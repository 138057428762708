<template>
    <div id="content" :class="[labelSelected ? 'label-print' : 'label']">
        <template v-if="!queueSelected && !labelSelected && aStatesToPrint.length == 0">
            <template v-if="['portrait'].includes($mq)">
                <div class="portrait-label-container">
                    <div class="portrait-menu">
                        <portrait-top-menu :allStates="allStates" @statesSwitch="statesSwitch" @selectStateFilter="selectStateFilter" @setTabulation="setTabulation" :collectionSelected="sSelectedCollection" @returnToCollections="unselectCollection" @searchInput="searchBar" @returnToCategories="clickCat(false)" @isSearching="clickCat" @searchStates="clickState(false)" :allCategorySelected="catSelPortrait" :categorySelected="category" :categories="iTabulationType == 1" :collections="iTabulationType == 2" :previousSearchInput="searchInput" :previousStateSelected="stateSelected"></portrait-top-menu>
                    </div>
                    <!-- ALERTAS Y ERRORES -->
                    <div v-if="!activePredef" class="content" :class="[categories || predefined ? 'right' : 'full', Object.values(categories).length == 0 ? 'full' : 'right']">
                        <div id="message" ref="message" v-if="!cantSelectCategoryPrinterError">
                            <template v-if="!isApp && !checkWindows">
                                <Message :message="$t('config.printer.windows.noWindows')" type="error" :buttonLabel="$t('config.printer.windows.downloadAndy')" @buttonAction="gotoURL($store.getters['login/getAppAndroid'] ? $store.getters['login/getAppAndroid'] : 'market://details?id=com.intowin.alex')"></Message>
                            </template>
                            <template v-else>
                                <Message v-if="isApp && Object.values(categories).length != 0" :message="printerError" :loader="checkingStatusPrinter" :buttonLabel="$t('config.printer.validate')" @buttonAction="validatePrinter" type="error"></Message>
                                <!-- <Message v-else-if="noPrinterAvailable" :message="$t('label.printer.alert_empty')" :buttonLabel="$t('label.printer.alert_save')" @buttonAction="goToConfig" type="error"></Message> -->
                                <Message v-else-if="!isApp && Object.values(categories).length != 0" :message="printerError" :loader="checkingStatusPrinter" :buttonLabel="$t('config.printer.errors.go_configuration')" @buttonAction="goToConfig" type="error"></Message>
                                <Message
                                    v-if="queueLabels > 0"
                                    :key="queueLabels"
                                    :message="
                                        $tc('label.queue.message_pending', queueLabels, {
                                            s: queueLabels
                                        })
                                    "
                                    :buttonLabel="$t('label.queue.manage')"
                                    @buttonAction="queueSelected = !queueSelected"
                                    type="warning"
                                ></Message>
                            </template>
                        </div>
                    </div>

                    <div class="categories-container hide-scrollbar">
                        <div class="portrait-categories" v-if="Object.values(categories).length != 0" :class="bCategories && bCollections ? 'column-withtab' : ''">
                            <!-- Categories -->
                            <template v-if="iTabulationType == 1">
                                <div v-if="!category && !allCategories && !stateSwitch && !stateSelected && !allStates">
                                    <!-- All categories box -->
                                    <div class="item all" v-if="sortedCategories" @click="clickCat('allCategories')" :class="{ selected: !category && !activePredef }">
                                        <span class="color" v-bind:style="{ backgroundColor: '#475b96' }"></span>
                                        <span class="text">{{ $t('label.categories.all') }}</span>
                                    </div>
                                    <!-- Single category -->
                                    <div
                                        v-for="category in sortedCategories"
                                        class="item"
                                        :class="{ selected: category.selected && !activePredef }"
                                        :key="category.id"
                                        :style="
                                            category.selected
                                                ? {
                                                      backgroundColor: category.selected && !activePredef ? category.color : 'transparent'
                                                  }
                                                : {}
                                        "
                                        @click="clickCat(category)"
                                    >
                                        <span class="color" v-bind:style="{ backgroundColor: category.color }"></span>
                                        <span class="text">{{ category.name }}</span>
                                        <span class="count" v-show="getSelectedCatLab(category.id) > 0">{{ getSelectedCatLab(category.id) }}</span>
                                    </div>
                                </div>
                                <div class="states-container" v-else-if="stateSwitch && !stateSelected && !allStates">
                                    <!-- All states box -->
                                    <div class="item all" v-if="allLabelStates" @click="clickState(false)" :class="{ selected: !category && !activePredef }">
                                        <span class="text">{{ $t('label.all_states') }}</span>
                                    </div>
                                    <!-- Single category -->
                                    <div
                                        v-for="state in allLabelStates"
                                        class="item"
                                        :class="{ selected: state.selected && !activePredef }"
                                        :key="state.id"
                                        :style="
                                            state.selected
                                                ? {
                                                      backgroundColor: state.selected && !activePredef ? category.color : 'transparent'
                                                  }
                                                : {}
                                        "
                                        @click="clickState(state.id)"
                                    >
                                        <span class="text">{{ state.name }}</span>
                                    </div>
                                </div>

                                <!-- LABELS -->
                                <div v-else class="label-content">
                                    <div class="container scrollbar" ref="container" id="label_container">
                                        <template v-if="labels && num_columns">
                                            <virtual-list ref="labelItems" :keeps="8" :estimate-size="155" class="virtual-list list-keep scroll-touch hide-scrollbar" :item-class="'list-item-keep'" :data-key="'id'" :data-sources="labelsFiltered" :data-component="labelComponent" />
                                        </template>
                                    </div>
                                </div>
                            </template>

                            <!-- Collections -->
                            <template v-else-if="iTabulationType == 2 && sSelectedCollection == -1">
                                <div v-for="(collection, index) in collections" :key="index">
                                    <div class="item" :class="{ selected: sSelectedCollection == collection.id }" @click="clickCollection(collection.id)">
                                        {{ collection.name }}
                                    </div>
                                </div>
                            </template>
                            <template v-if="sSelectedCollection != -1">
                                <div class="content hide-scrollbar right" id="collections" :key="sSelectedCollection">
                                    <!-- Loop for each reference -->
                                    <template v-for="reference in sortByName(collections[sSelectedCollection].references)" :reference="reference">
                                        <!-- for each reference, create state -->
                                        <ReferenceState v-for="state in sortByPriority(reference.states)" :key="reference.id + state.id + sSelectedCollection" :state="state" :collectionID="sSelectedCollection" :referenceID="reference.id" :referenceName="reference.name" :referenceImage="reference.image" @showPrintButton="checkShowPrintButton()"></ReferenceState>
                                    </template>
                                </div>
                            </template>
                        </div>

                        <div class="empty-page" v-if="Object.values(categories).length == 0">
                            <EmptyPage :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.product_description')"></EmptyPage>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <!-- Categories Container -->
                <div v-if="!activePredef" class="content hide-scrollbar" :class="[categories || predefined ? 'right' : 'full', Object.values(categories).length == 0 ? 'full' : 'right']">
                    <div id="message" ref="message" v-if="!cantSelectCategoryPrinterError">
                        <Message v-if="isApp && Object.values(categories).length != 0" :message="printerError" :loader="checkingStatusPrinter" :buttonLabel="$t('config.printer.validate')" @buttonAction="validatePrinter" type="error"></Message>
                        <!-- <Message v-else-if="noPrinterAvailable" :message="$t('label.printer.alert_empty')" :buttonLabel="$t('label.printer.alert_save')" @buttonAction="goToConfig" type="error"></Message> -->
                        <Message v-else-if="!isApp && Object.values(categories).length != 0" :message="printerError" :loader="checkingStatusPrinter" :buttonLabel="$t('config.printer.errors.go_configuration')" @buttonAction="goToConfig" type="error"></Message>
                        <Message
                            v-if="queueLabels > 0"
                            :key="queueLabels"
                            :message="
                                $tc('label.queue.message_pending', queueLabels, {
                                    s: queueLabels
                                })
                            "
                            :buttonLabel="$t('label.queue.manage')"
                            @buttonAction="queueSelected = !queueSelected"
                            type="warning"
                        ></Message>
                    </div>
                    <template v-if="iTabulationType == 1">
                        <!-- <div class="fast-bar hide-scrollbar" ref="letters" v-if="(category == false && Object.keys(labels).length > 20) || (category != false && Object.keys(category.references).length > 20)" :class="{ hide: !highlight_letter }" :style="'height: calc(100% - (15px + ' + statesHeight + 'px));'">
                            <button
                                v-for="(letter, position) in abc"
                                class="btn"
                                :id="letter"
                                :class="{
                                    empty: !letters[letter],
                                    active: current_letter == letter,
                                    highlight: highlight_letter == letter
                                }"
                                @click="letters[letter] ? letterScroll(letter) : null"
                            >
                                {{ letter }}
                            </button>
                        </div> -->
                        <div class="states" id="states" :class="{ 'alert-active': printerError }">
                            <!-- <span class="states-title" v-if="Object.values(categories).length != 0">{{ $t('label.title_states') }}:</span> -->
                            <div class="state-options hide-scrollbar" ref="state_options">
                                <span v-if="Object.values(categories).length != 0" class="state" ref="state_all" :class="{ selected: stateSelected == false }" @click="stateSelected = false">{{ $t('label.all') }}</span>

                                <span
                                    class="state"
                                    v-for="(state, index) in labelStates"
                                    :class="{
                                        selected: state.id == stateSelected,
                                        hide: !showMoreStates && numberOfStatesToShow !== false && (numberOfStatesToShow == 0 || numberOfStatesToShow < index + 1),
                                        transparent: numberOfStatesToShow === false
                                    }"
                                    @click="stateSelected = stateSelected == state.id ? false : state.id"
                                    ref="states"
                                    :key="index"
                                >
                                    {{ state.name }}
                                </span>

                                <span
                                    v-if="numberOfStatesToShow < labelStates.length"
                                    ref="state_more"
                                    class="state display"
                                    :class="{
                                        transparent: numberOfStatesToShow === false,
                                        less: showMoreStates
                                    }"
                                    @click="showMoreStates = !showMoreStates"
                                >
                                    <template v-if="showMoreStates">
                                        {{ $t('label.less') }}
                                    </template>
                                    <template v-else>
                                        {{ $t('label.more') }}
                                    </template>
                                </span>

                                <div class="empty-page" v-if="Object.values(categories).length == 0">
                                    <EmptyPage :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.product_description')"></EmptyPage>
                                </div>
                            </div>
                        </div>

                        <div class="search-container" v-if="Object.values(categories).length > 0">
                            <input class="search" type="text" v-model="debounceSearchInput" @input="searchBar(debounceSearchInput)" />
                            <div v-if="searchInput != ''" class="close" @click="cleanSearch()"></div>
                        </div>

                        <div v-if="Object.values(categories).length != 0" class="container scrollbar" ref="container" id="label_container" :style="printerError ? 'height: calc(100% - 95px - (' + (statesHeight + messageHeight) + 'px));' : 'height: calc(100% - (75px + ' + statesHeight + 'px));'" :class="{ 'alert-active': printerError }">
                            <template v-if="labels">
                                <template v-if="num_columns">
                                    <virtual-list ref="labelItems" :keeps="8" :estimate-size="155" class="virtual-list list-keep scroll-touch hide-scrollbar" :item-class="'list-item-keep'" :data-key="'id'" :data-sources="labelsFiltered" :data-component="labelComponent" />
                                </template>
                            </template>
                            <p v-else class="empty">
                                {{ $t('label.empty_labels') }}
                            </p>
                        </div>
                    </template>
                    <!-- Collection container -->
                    <template v-else>
                        <div id="collections" v-if="sSelectedCollection != -1" :key="sSelectedCollection">
                            <!-- Loop for each reference -->
                            <template v-for="reference in sortByName(collections[sSelectedCollection].references)" :reference="reference">
                                <!-- for each reference, create state -->
                                <ReferenceState v-for="state in sortByPriority(reference.states)" :key="reference.id + state.id + sSelectedCollection" :state="state" :collectionID="sSelectedCollection" :referenceID="reference.id" :referenceName="reference.name" :referenceImage="reference.image" @showPrintButton="checkShowPrintButton()"></ReferenceState>
                            </template>
                        </div>
                    </template>
                </div>

                <!-- Tabulation -->
                <div class="tabulation" v-if="bCategories && bCollections">
                    <div class="category" :class="{ selected: iTabulationType == 1 }" @click=";(iTabulationType = 1), $bar.hide()">
                        <div class="icon"></div>
                        <span class="text">{{ $t('label.title_categories') }}</span>
                    </div>
                    <div class="collection" :class="{ selected: iTabulationType == 2 }" @click=";(iTabulationType = 2), getFirstCollection(), checkShowPrintButton()">
                        <div class="icon"></div>
                        <span class="text">{{ $t('label.title_predefined') }}</span>
                    </div>
                </div>

                <div class="column left hide-scrollbar categories" v-if="Object.values(categories).length != 0" :class="bCategories && bCollections ? 'column-withtab' : ''">
                    <!-- Categories -->
                    <template v-if="iTabulationType == 1">
                        <div class="item all" v-if="sortedCategories" @click="clickCat(false)" :class="{ selected: !category && !activePredef }">
                            <span class="color" v-bind:style="{ backgroundColor: '#475b96' }"></span>
                            <span class="text">{{ $t('label.categories.all') }}</span>
                        </div>
                        <template v-if="sortedCategories">
                            <div
                                v-for="category in sortedCategories"
                                class="item"
                                :class="{ selected: category.selected && !activePredef }"
                                :key="category.id"
                                :style="
                                    category.selected
                                        ? {
                                              backgroundColor: category.selected && !activePredef ? category.color : 'transparent'
                                          }
                                        : {}
                                "
                                @click="clickCat(category)"
                            >
                                <span class="color" v-bind:style="{ backgroundColor: category.color }"></span>
                                <span class="text">{{ category.name }}</span>
                                <span class="count" v-show="getSelectedCatLab(category.id) > 0">{{ getSelectedCatLab(category.id) }}</span>
                            </div>
                        </template>
                    </template>

                    <!-- Collections -->
                    <template v-else>
                        <div v-for="(collection, index) in collections" :key="index">
                            <div class="collection" :class="{ selected: sSelectedCollection == collection.id }" @click="clickCollection(collection.id)">
                                {{ collection.name }}
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </template>

        <template v-else-if="queueSelected">
            <printQueue :printerError="printerError" @initStatusPrinter="initStatusPrinter" @back="closePrintErrorQueue"></printQueue>
        </template>

        <template v-else>
            <print :label="labelSelected" :states="aStatesToPrint" :labelStateSelected="labelStateSelected" :printer_available="activePrint" @print="printPrepare" @unselect="printCancel"></print>
        </template>
    </div>
</template>

<script>
import LabelBox from '../components/domain/label/labelBox.vue'
import LabelItem from '../components/domain/label/labelItem.vue'
import PortraitTopMenu from '@/components/domain/label/portraitTopMenu.vue'
import printQueue from '@/components/domain/label/printQueue.vue'
import EmptyPage from '../components/layout/EmptyPage.vue'
import print from '@/components/domain/label/print'
import ReferenceState from '@/components/domain/label/ReferenceState'
import Message from '@/components/domain/ui/Message'
import * as bpac from '@/../public/js/bpac.js'
import i18n from '@/i18n'
import VirtualList from 'vue-virtual-scroll-list'

export default {
    name: 'Label',
    components: {
        print,
        ReferenceState,
        Message,
        EmptyPage,
        PortraitTopMenu,
        printQueue,
        LabelBox,
        VirtualList
    },
    data() {
        return {
            allStates: false,
            stateSwitch: false,
            allCategories: false,
            cantSelectCategoryPrinterError: false,
            holding: false,
            holdingCat: false,
            selectedLab: {},
            printQueue: [],
            activePredef: false,
            actTab: false,
            fbar: {},
            activePrint: false,
            printData: [],
            num_copies: 0,
            next_state: false,
            oParams: {},
            labelSelected: false,
            queueSelected: false,
            abc: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'U', 'V', 'W', 'X', 'Y', 'Z'],
            current_letter: '',
            highlight_letter: false,
            scrolling: false,
            scrollWait: false,
            noscroll: false,
            stateSelected: false,
            labelsNoPhoto: true,
            // printer: false,
            // config: false,
            // label: false,
            statesHeight: 45,
            messageHeight: 0,
            iTabulationType: 1, // 1 => category, 2 => collection
            sSelectedCollection: -1,
            bCollectionPrintButton: true,
            aStatesToPrint: [],
            numberOfStatesToShow: false,
            showMoreStates: false,
            labelStateSelected: '',
            iIntervalStatusPrinter: null,
            bSendPrintError: false,
            can_validateprinter: true,
            searchInput: '',
            debounceSearchInput: '',
            timetoPrintW: { i: false, e: false },
            timetoPrintP: { i: false, e: false },
            catSelPortrait: false,
            labelComponent: LabelItem,
            breakpoints: { 350: 1, 700: 2, 1000: 3, 1200: 4, 1500: 5 },
            num_columns: 0,
            timer: false,
            timerSearch: false,
            currentLabelIndex: 0
        }
    },
    computed: {
        allLabelStates() {
            var states = this.$store.getters['label/getLabelStates']()
            var statesSorted = Object.values(states).sort((a, b) => (parseInt(a.priority) > parseInt(b.priority) ? 1 : -1))
            return statesSorted
        },
        queueLabels() {
            let labels = this.$store.getters['label/getPrintLogsAndroid']
            log('queueLabels', labels)
            return Object.keys(labels).length
        },

        checkWindows() {
            return getOS() == 'Windows' || getOS() == 'Linux'
        },

        getRoleLoggedUser() {
            var user = this.$store.getters['loginUser/getLocalEmployee']
            var roleUserLogged = this.$store.getters['employee/getEmployee'](user)
            var levelRoleUserLogged = false
            if (roleUserLogged) {
                levelRoleUserLogged = this.$store.getters['employee/getRol'](roleUserLogged.rol)
            }
            return levelRoleUserLogged
        },
        apiURL() {
            return this.$store.getters['login/getAPIurl']
        },
        lateralMenu() {
            return this.$store.state['menu_tools_is_expanded']
        },
        isApp() {
            var bIsApp = process.env.VUE_APP_IS_APP == 'TRUE'
            // if (!bIsApp) {
            //     this.showMoreStates = true
            // }
            return bIsApp
        },
        collections() {
            return this.$store.getters['label/getCollections']
        },
        categories() {
            // console.log("categories");
            // console.log(this.$store.getters['label/getCategories']);
            return this.$store.getters['label/getCategories']
            // return false;
        },
        sortedCategories() {
            return this.$store.getters['label/getSortedCategories']
        },
        category() {
            return this.$store.getters['label/getSelectedCategory']
        },
        labels() {
            // console.log("labels");
            // console.log(this.$store.getters['label/getLabelsOrdered'])
            return this.$store.getters['label/getLabelsOrdered']
            // return undefined;
        },
        labelsFiltered() {
            var rows = {}
            var row = 1
            var item = 0

            var max_items = this.num_columns

            var show_cat = !this.category.id

            for (var idx in this.labels) {
                var label = this.labels[idx]
                var show = true

                if (this.category) {
                    show = label.category_id == this.category.id
                }
                if (show && this.stateSelected && this.stateSelected != 'all') {
                    show = label.states[this.stateSelected] != undefined
                }

                var labelName = cleanString(label.name.trim().toLowerCase())
                var searchInput = cleanString(this.searchInput.trim().toLowerCase())
                var searchWords = searchInput
                // var searchWords = searchInput.split(' ').join('|')

                if (show && searchInput != '') {
                    const searchRegex = new RegExp(searchWords, 'g')
                    show = labelName.match(searchRegex)
                }

                if (show) {
                    // Label to show

                    if (item == 0) {
                        rows[row] = {
                            id: row,
                            columns: max_items,
                            items: [],
                            show_cat: show_cat
                        }
                    }
                    rows[row].items.push(label)
                    if (item < max_items - 1) {
                        item++
                    } else {
                        row++
                        item = 0
                    }
                }
            }

            return Object.values(rows)
        },

        selecting() {
            return Object.keys(this.selectedLab).length > 0
        },
        selectedLabLength() {
            return Object.keys(this.selectedLab).length
        },

        letters() {
            for (var i in this.labels) {
                var lab = this.labels[i]
                var first = lab.name.charAt(0).toUpperCase()

                if (Object.keys(this.fbar).indexOf(first) == -1) {
                    this.fbar[first] = []
                }
                this.fbar[first].push(lab.id)
            }
            return this.fbar
        },

        labelStates() {
            var labels
            if (this.category) {
                labels = { ...this.category.references }
            } else {
                labels = { ...this.labels }
            }

            var states = {}
            for (var i in labels) {
                var label = labels[i]
                label.image != false ? (this.labelsNoPhoto = false) : true
                for (var j in label.states) {
                    if (!Object.keys(states)[j]) {
                        states[j] = label.states[j]
                    }
                }
            }

            var statesStorted = Object.values(states).sort((a, b) => (parseInt(a.priority) > parseInt(b.priority) ? 1 : -1))

            return statesStorted
        },

        bCategories() {
            return Object.keys(this.categories).length > 0
        },

        bCollections() {
            return Object.keys(this.collections).length > 0
        },

        printerError() {
            log('---printerError---')
            if (this.$store.getters['printer/getStatusPrinter']) {
                return false
            } else {
                const self = this
                const platform = process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2
                const appVersion = process.env.VUE_APP_VERSION

                var oError = this.$store.getters['printer/getStatusErrorPrinter']
                if (oError.msg == 'config.printer.errors.bpac_fail_routh') {
                    var config = false
                    var current_printer = self.$store.getters['printer/getCurrentPrinter']
                    if (typeof current_printer === 'undefined') {
                        var printersConfig = self.$store.getters['printer/getPrinterConfig']
                        for (var p in printersConfig) {
                            if (!config) {
                                var pc = printersConfig[p]
                                if ((pc.platform == 'android' && process.env.VUE_APP_IS_APP === 'true') || (pc.platform == 'windows' && process.env.VUE_APP_IS_APP !== 'true')) {
                                    log('ASSIGN -> ', pc)
                                    config = Object.assign({}, pc)
                                }
                            }
                        }
                    } else {
                        // console.warn(context.getters.getPrinterConfig(current_printer))
                        config = Object.assign({}, self.$store.getters['printer/getPrinterConfig'](current_printer))
                    }
                    var templates = self.$store.getters['printer/getTemplatesByPrinterModel'](config.model)
                    var label = false
                    for (var t in templates) {
                        var labelAux = self.$store.getters['printer/getLabels'](templates[t].label_id)
                        if (labelAux.tpl_file_windows) {
                            label = Object.assign({}, labelAux)
                        }
                    }
                    if (label) {
                        var tpl_path = config.path ? config.path : false
                        if (tpl_path.slice(-1) != '/' && tpl_path.slice(-1) != '\\') tpl_path = tpl_path + '/'
                        var tpl_file = label.tpl_file_windows

                        oError.msg = i18n.t(oError.msg).replace('[path]', tpl_path + tpl_file)
                    } else {
                        oError.msg = 'config.printer.errors.default'
                    }
                }

                if (self.bSendPrintError) {
                    self.$store
                        .dispatch('label/addPrintError', {
                            error: i18n.t(oError.msg, { s: oError.code }),
                            platform: platform,
                            version: appVersion
                        })
                        .then(function (response) {
                            self.bSendPrintError = false
                        })
                }

                return i18n.t(oError.msg, { s: oError.code })
            }
        },

        checkingStatusPrinter() {
            return this.$store.getters['printer/isCheckingStatusPrinter']
        },

        home() {
            return this.$store.getters['getHome'] ? this.$store.getters['getHome'] : '/home'
        },

        isValidatebPac() {
            return this.$store.getters['printer/getIsValidatebPac']
        },

        tpRound() {
            return this.$store.getters.getTimerProductRound
        }
    },
    watch: {
        $route: 'load',
        selecting: 'changeSelection',
        selectedLabLength: 'changeSelectionLength',
        '$route.params': 'load',

        lateralMenu(newCount, oldCount) {
            // console.log(newCount, oldCount)
            if (newCount) {
                this.$popup.close()
            } else {
                this.load()
            }
        }
    },
    methods: {
        clickState(state) {
            if (!state) {
                this.allStates = true
            } else {
                this.stateSelected = state
                this.stateSwitch = false
            }
        },
        statesSwitch(val) {
            if (val == 'return') {
                this.allStates = false
            } else if (val) {
                // alert('hi')
                // console.log(val)
                // this.stateSelected = false
            }
            this.stateSwitch = val
        },
        labelQueue() {
            this.$store.dispatch('label/getPrintLogsAndroid')
        },

        unselectCollection() {
            this.sSelectedCollection = -1
        },
        setTabulation(number) {
            this.iTabulationType = number
        },

        goToConfig() {
            this.$router.push({
                name: 'Config',
                params: {
                    page: 'printer'
                }
            })
        },

        gotoURL(url) {
            if (url) window.open(url, '_blank')
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },

        checkAccess() {
            var self = this

            if (self.currentEmployee() && self.$store.getters['hasAccess'](self.currentEmployee().id, 0)) {
                self.checkPrinter(false, true)
            } else {
                // IF SESION EXPIRED REDIRECT TO HOME AND ENTER THE PIN
                const kitchenMode = localStorage.getItem('kitchenMode')
                                      ? (localStorage.getItem('kitchenMode') === 'true' ? true : false)
                                      : false

                if(kitchenMode) return

                self.$router.push(self.$root.redirectPath(self.home))
                //     this.$popup.login({
                //         closable: false,
                //         blocking: false,
                //         list: this.$store.getters['employee/getLoginUsers'](0),
                //         callAfterAccess: function(response) {
                //             if (response) {
                //                 self.$popup.close()
                //                 self.checkPrinter()
                //             }
                //         }
                //     })
            }
        },

        checkPrinter(bValidateAndroid, bValidateWindows) {
            var bValidateAndroid = typeof bValidateAndroid !== 'undefined' ? bValidateAndroid : true
            var bValidateWindows = typeof bValidateWindows !== 'undefined' ? bValidateWindows : true

            var self = this
            var error = []

            var current_printer = this.$store.getters['printer/getCurrentPrinter']
            // console.log('checkPrinter, current_printer --> ', current_printer, this.$store.getters['printer/getPrinterConfig'](current_printer))
            var config = null
            if (!current_printer || !this.$store.getters['printer/getPrinterConfig'](current_printer)) {
                var printersConfig = this.$store.getters['printer/getPrinterConfig']()
                for (var p in printersConfig) {
                    if (config == null) {
                        var pc = printersConfig[p]
                        // if ((pc.platform == 'android' && this.$store.getters.getIsApp) || (pc.platform == 'windows' && !this.$store.getters.getIsApp)) {
                        if ((pc.platform == 'windows' && !this.$store.getters.getIsApp) || pc.mode == 'WIFI' || pc.print_mode == 'wifi' || (this.$store.getters.getIsApp && pc.platform == cordova.platformId)) {
                            config = Object.assign({}, pc)
                            this.$store.dispatch('printer/setCurrentPrinter', pc.id)
                        }
                    }
                }
            } else {
                config = Object.assign({}, this.$store.getters['printer/getPrinterConfig'](current_printer))
            }
            log('checkPrinter, config --> ', config)
            var bCanPrintWindows = false
            if (config) {
                var printer = this.$store.getters['printer/getModels'](config.model)
                log('checkPrinter, printer --> ', printer)
                if (printer) {
                    var templates = this.$store.getters['printer/getTemplatesByPrinterModel'](printer.id)
                    for (var k in templates) {
                        var label = self.$store.getters['printer/getLabels'](templates[k].label_id)
                        if (!bCanPrintWindows && typeof label.tpl_file_windows !== 'undefined' && label.tpl_file_windows != null) {
                            bCanPrintWindows = true
                        }
                    }
                    console.log('checkPrinter', bCanPrintWindows)
                    if (self.$store.getters.getIsApp || (bCanPrintWindows || config.mode != 'USB')) {
                        // var printer = this.$store.getters['printer/getPrinter']
                        console.log(JSON.parse(JSON.stringify(printer)))
                        // console.log(auxPrinter)
                        if ((typeof printer.name === 'undefined' || !printer.name) && (typeof printer.mode === 'undefined' || !printer.mode) && self.$store.getters.getIsApp) {
                            error.push(true)
                        } else if (!bCanPrintWindows && !self.$store.getters.getIsApp && config.mode == 'USB') {
                            error.push(true)
                        }
                        //TODO VALIDATE HOW DETECT IF ALL TEMPLATES ARE LOADED
                        // else if ((typeof auxPrinter.template_loaded === 'undefined' || !auxPrinter.template_loaded) && printer.templates[printer.config.template_id].model.sdk == 'brother' && process.env.VUE_APP_IS_APP == 'TRUE') {
                        //     error.push(true)
                        // }

                        if (error.indexOf(true) != -1) {
                            this.cantSelectCategoryPrinterError = true
                            // console.log('sin configuracion')
                            // if (this.getRoleLoggedUser && this.getRoleLoggedUser.level > 1) {
                            //     self.$popup.alert({
                            //         closable: false,
                            //         blocking: false,
                            //         customClass: 'popup-label-admin',
                            //         message: self.$t('label.printer.alert_empty'),
                            //         textSave: self.$t('label.printer.alert_save'),
                            //         callSave: function (response) {
                            //             self.$router.push({
                            //                 name: 'Config',
                            //                 params: { page: 'printer' }
                            //             })
                            //         }
                            //     })
                            // } else {
                            //     self.$popup.alert({
                            //         blocking: false,
                            //         closable: false,
                            //         customClass: 'popup-label',
                            //         message: self.$t('label.printer.alert_role_level_1')
                            //     })
                            // }
                        } else {
                            console.log('checkPrinter', 'All OK initStatusPrinter')
                            self.initStatusPrinter(bValidateAndroid, bValidateWindows)
                        }
                    }
                } else {
                    this.cantSelectCategoryPrinterError = true
                    // console.log('sin configuracion')
                    if (this.getRoleLoggedUser && this.getRoleLoggedUser.level > 1) {
                        // self.$popup.alert({
                        //     closable: false,
                        //     blocking: false,
                        //     customClass: 'popup-label-admin',
                        //     message: self.$t('label.printer.alert_empty'),
                        //     textSave: self.$t('label.printer.alert_save'),
                        //     callSave: function (response) {
                        //         self.$router.push({
                        //             name: 'Config',
                        //             params: { page: 'printer' }
                        //         })
                        //     }
                        // })
                    } else {
                        // self.$popup.alert({
                        //     blocking: false,
                        //     closable: false,
                        //     customClass: 'popup-label',
                        //     message: self.$t('label.printer.alert_role_level_1')
                        // })
                    }
                }
            } else {
                this.cantSelectCategoryPrinterError = true
                // console.log('sin configuracion')
                if (this.getRoleLoggedUser && this.getRoleLoggedUser.level > 1) {
                    // self.$popup.alert({
                    //     closable: false,
                    //     blocking: false,
                    //     customClass: 'popup-label-admin',
                    //     message: self.$t('label.printer.alert_empty'),
                    //     textSave: self.$t('label.printer.alert_save'),
                    //     callSave: function (response) {
                    //         self.$router.push({
                    //             name: 'Config',
                    //             params: { page: 'printer' }
                    //         })
                    //     }
                    // })
                } else {
                    // self.$popup.alert({
                    //     blocking: false,
                    //     closable: false,
                    //     customClass: 'popup-label',
                    //     message: self.$t('label.printer.alert_role_level_1')
                    // })
                }
            }
            // console.log('---------------')
        },
        validatePrinter() {
            if (this.printerError == i18n.t('config.printer.errors.bpac_no_installed')) {
                window.open('https://chrome.google.com/webstore/detail/ilpghlfadkjifilabejhhijpfphfcfhb', '_blank')
            }
            this.initStatusPrinter()
        },

        initStatusPrinter(bLaunchAndroidValidate, bLaunchWindowValidate) {
            var self = this
            var bLaunchAndroid = typeof bLaunchAndroidValidate !== 'undefined' ? bLaunchAndroidValidate : true
            var bLaunchWindow = typeof bLaunchWindowValidate !== 'undefined' ? bLaunchWindowValidate : true
            log('initStatusPrinter', bLaunchWindow)
            if (!this.checkingStatusPrinter) {
                var config = null
                var current_printer = this.$store.getters['printer/getCurrentPrinter']
                if (!current_printer || !this.$store.getters['printer/getPrinterConfig'](current_printer)) {
                    var printersConfig = this.$store.getters['printer/getPrinterConfig']()
                    for (var p in printersConfig) {
                        if (config == null) {
                            var pc = printersConfig[p]
                            // if ((pc.platform == 'android' && this.$store.getters.getIsApp) || (pc.platform == 'windows' && !this.$store.getters.getIsApp)) {
                            if ((pc.platform == 'windows' && !this.$store.getters.getIsApp) || pc.mode == 'WIFI' || pc.print_mode == 'wifi' || (this.$store.getters.getIsApp && pc.platform == cordova.platformId)) {
                                config = Object.assign({}, pc)
                                this.$store.dispatch('printer/setCurrentPrinter', pc.id)
                            }
                        }
                    }
                } else {
                    config = Object.assign({}, this.$store.getters['printer/getPrinterConfig'](current_printer))
                }
                if ((this.$store.getters.getIsApp && bLaunchAndroid) || (config.platform == 'windows' && !this.$store.getters.getIsApp && config.mode != 'USB')) {
                    // clearInterval(this.iIntervalStatusPrinter)
                    this.$store.dispatch('printer/statusPrinter')
                    var self = this
                    // this.iIntervalStatusPrinter = setInterval(function() {
                    //     self.$store.dispatch('printer/statusPrinter')
                    // }, 60000)
                } else if (!this.$store.getters.getIsApp && bLaunchWindow) {
                    if (!this.isValidatebPac) {
                        return self.$store.dispatch('printer/validateBpac')
                    }
                }
            }
        },

        printWindows() {
            // TODO - Get path from config
            const DATA_FOLDER = 'C:\\ALEX\\'
            const location = this.$store.getters['login/getLocation']
            const account = this.$store.getters['login/getAccount']
            var TEMPLATE = ''

            if (account.id == 'VDRwZDdKVVpvSWlqRURuSW5TV0FlUT09') {
                TEMPLATE = 'td4550_ph.lbx'
            } else if (location.id == 'QjJkcU40eUdmc1lPK1FDWmY1Uzlqdz09') {
                // CR
                TEMPLATE = 'td2120_tp.lbx'
            } else if (location.id == 'QmxrSC83aTNUejVXTlozaDV0NlBDQT09') {
                // IR
                TEMPLATE = 'td4550_tp.lbx'
            }

            var self = this

            // console.log(TEMPLATE)

            window.DoPrint = async function DoPrint() {
                if (bpac.IsExtensionInstalled() == false) {
                    const agent = window.navigator.userAgent.toLowerCase()
                    const ischrome = agent.indexOf('chrome') !== -1 && agent.indexOf('edge') === -1 && agent.indexOf('opr') === -1
                    if (ischrome) {
                        window.open('https://chrome.google.com/webstore/detail/ilpghlfadkjifilabejhhijpfphfcfhb', '_blank')
                    }
                    return
                }

                try {
                    const strPath = DATA_FOLDER + TEMPLATE
                    const objDoc = bpac.IDocument
                    const ret = await objDoc.Open(strPath)

                    if (ret == true) {
                        for (var i in self.printData) {
                            var item = self.printData[i]
                            // console.log(item)
                            let field = await objDoc.GetObject(item.k)
                            if (typeof field !== 'undefined') {
                                field.Text = item.v
                            }
                        }

                        objDoc.StartPrint('', 0)
                        objDoc.PrintOut(self.num_copies, 0)
                        objDoc.EndPrint()

                        objDoc.Close()
                    }
                } catch (e) {
                    logError(e)
                }
            }

            DoPrint()
        },

        load(refresh = true) {
            var self = this

            this.checkAccess()
            self.$store.dispatch('label/sendFailedLabelsPrint')

            self.$store.dispatch('label/loadLabels', {})

            // TODO - First Access parameter to store
            // TODO remove tRUE
            // if (true || process.env.VUE_APP_IS_APP == "TRUE") {
            if (this.$store.getters.getIsApp && this.can_validateprinter) {
                self.$store.dispatch('printer/checkPrinterConfiguration', {}).then(function () {
                    var update = self.$store.getters['printer/getUpdateTemplate']
                    if (update) {
                        self.$popup.alert({
                            closable: false,
                            blocking: false,
                            customClass: 'popup-label-admin',
                            message: self.$t('label.update.message'),
                            textSave: self.$t('label.update.confirm'),
                            textCancel: self.$t('label.update.cancel'),
                            callSave: function () {
                                // self.$router.push("/config/printer");
                                self.$router.push({
                                    name: 'ConfigPage',
                                    params: {
                                        page: 'printer',
                                        update: true,
                                        config_id: update
                                    }
                                })
                            }
                        })
                    }
                })
            }

            // self.$bar.addAction('print', {
            //   callback: function () {
            //     for (var i in self.selectedLab) {
            //       var d = self.selectedLab[i]
            //       self.printQueue.push(d)
            //     }
            //     if (!self.activePredef) {
            //       self.selectedLab = {}
            //     }
            //     self.printPopup()
            //   }
            // })
            // self.$bar.addAction('cancel', {
            //   callback: function () {
            //     Vue.set(self.selectedLab, {})
            //     self.selectedLab = {}
            //     self.holding = false
            //   }
            // })
            self.$bar.reset()
            self.$bar.hide()
            if (this.$route.params.queue) {
                this.queueSelected = true
            } else if(this.$route.params.print){
                let type = this.$route.params.type ? this.$route.params.type : 'label'
                let id = this.$route.params.id ? this.$route.params.id : false
                if(id){
                    if(type == 'collection'){
                        this.sSelectedCollection = id
                        this.printStates()
                    } else {

                    }
                }
            } else if(this.$route.query.print){
                let type = this.$route.query.type ? this.$route.query.type : 'label'
                let id = this.$route.query.id ? this.$route.query.id : false
                if(id){
                    if(type == 'collection'){
                        this.sSelectedCollection = id
                        this.printStates()
                        this.$router.replace({name:'Label'}).catch(err => {})
                    } else {

                    }
                }
            } else {
                if (this.iTabulationType == 2) {
                    this.getFirstCollection()
                    this.checkShowPrintButton()
                }
            }
        },

        letterScroll(letter) {
            var scroll = {
                container: this.$refs.container,
                target: document.getElementById(this.fbar[letter][0])
            }
            this.$root.scrollTo(scroll)

            this.noscroll = true

            this.current_letter = letter

            this.highlightLetter(letter)

            var self = this
            this.scrolling = setTimeout(function () {
                self.noscroll = false
            }, 500)
        },

        getScroll() {
            if (((this.category == false && Object.keys(this.labels).length > 20) || (this.category != false && Object.keys(this.category.references).length > 20)) && !this.noscroll) {
                if (this.scrollWait) {
                    this.scrollWait = true
                }
                window.clearTimeout(this.scrolling)

                var self = this
                this.scrolling = setTimeout(function () {
                    self.handleScroll()
                    self.scrollWait = false
                }, 500)
            }
        },

        handleScroll(event) {
            var scroll_position = this.$refs.container.scrollTop

            var position = 1
            var finded = false
            while (position < Object.keys(this.labels).length && !finded) {
                var item_position = this.$refs.container.children[position].offsetTop

                if (scroll_position <= item_position) {
                    finded = true
                } else position++
            }
            var letter = this.labels[this.$refs.container.children[position].id].name.charAt(0).toUpperCase()
            this.current_letter = letter
            this.highlightLetter(letter)
        },

        highlightLetter(letter) {
            this.highlight_letter = letter

            window.clearTimeout(this.highlight)

            var self = this
            this.highlight = setTimeout(function () {
                self.highlight_letter = false
            }, 2000)

            var scroll = {
                container: this.$refs.letters,
                offset: -(this.$refs.letters.offsetHeight / 2 - 50),
                target: document.getElementById(letter)
            }
            this.$root.scrollTo(scroll)
        },

        labelClass(label) {
            var result = ''
            if (Object.keys(this.selectedLab).indexOf(label.id) != -1) {
                result = 'selected'
            } else if (label.name.charAt(0).toUpperCase() == this.highlight_letter && this.noscroll == true) {
                result = 'highlight'
            }

            if (this.labelsNoPhoto) {
                result = result + ' no-photo'
            }
            return result
        },
        searchBar(searchInput) {
            const self = this
            if (this.timerSearch) {
                clearTimeout(this.timerSearch)
                this.timerSearch = null
            }
            this.timerSearch = setTimeout(() => {
                self.searchInput = searchInput
            }, 500)
        },

        changeSelection() {
            var self = this
            if (this.selecting) {
                this.$bar.show()
            } else {
                this.$bar.hide()
            }
        },
        changeSelectionLength() {
            var self = this
            self.$bar.addAction('print-info', {
                label: String(self.selectedLabLength) + ' ' + self.$tc('label.selected', self.selectedLabLength),
                opened: true,
                expand: false
            })
            this.changeSelection()
        },

        clickLabel(label) {
            var self = this
            this.currentLabelIndex = label.index
            if (!this.holding && this.selecting) {
                Vue.delete(this.selectedLab, label.id)
            } else if (!this.holding) {
                self.printQueue.push(label)
                self.printPopup()
            } else if (Object.keys(this.selectedLab).length <= 0) {
                this.holding = false
            }
            // hack to avoid prop errors
            this.labelStateSelected = this.stateSelected ? this.stateSelected : ''
        },

        selectLabel(label) {
            if (this.holding) {
                if (Object.keys(this.selectedLab).indexOf(label.id) == -1) {
                    Vue.set(this.selectedLab, label.id, label)
                    this.selectedLab[label.id] = label
                } else {
                    Vue.delete(this.selectedLab, label.id)
                    delete this.selectedLab[label.id]
                }
            }
        },

        selectStateFilter(state) {
            this.$store.commit('label/selectCategory', false)
            this.clickCat(false)
            this.stateSelected = state
        },

        clickCat(category) {
            var container = document.getElementsByClassName('categories-container')[0]
            if (container) container.scrollTop = 0

            if (category !== 'allCategories') {
                this.allCategories = false
                this.catSelPortrait = false
                if (this.cantSelectCategoryPrinterError) {
                    return false
                } else {
                    if (this.activePredef) {
                        this.activePredef = false
                        this.selectedLab = {}
                    }
                    if (!this.holdingCat) {
                        this.$store.commit('label/selectCategory', category)
                    } else {
                        this.holdingCat = false
                    }
                    this.stateSelected = false
                }
            } else {
                this.allCategories = true
                this.catSelPortrait = true
            }

            this.debounceSearchInput = ''
            this.searchInput = ''
        },

        clickCollection(id) {
            this.sSelectedCollection = id
            this.checkShowPrintButton()
            document.getElementsByClassName('categories-container')[0].scrollTop = 0
        },

        clickPredefined(predef) {
            this.$store.commit('label/selectCategory', false)
            this.selectedLab = {}
            Object.assign(this.selectedLab, predef.references)
            this.activePredef = predef.id
        },

        selectCat(category) {
            if (this.holdingCat) {
                for (var i in this.labels) {
                    var label = this.labels[i]
                    if (label.category_id == category.id) {
                        this.holding = true
                        this.holdingCat = false
                        Vue.set(this.selectedLab, label.id, label)
                        this.selectedLab[label.id] = label
                    }
                }
            }
        },
        getSelectedCatLab(cat_id) {
            var result = 0
            for (var i in this.selectedLab) {
                var label = this.selectedLab[i]
                if (label.category_id == cat_id) result++
            }
            return result
        },

        printPopup() {
            this.labelSelected = this.printQueue[0]
            this.aStatesToPrint = []
            this.aStatesToPrint.push(this.labelSelected)
            // console.log('printPopup -> printPopup', this.labelSelected)
            this.$snackbar.close()

            // INIT WIZARD PROCESS SAVE TIME SPENT
            this.timetoPrintW.i = moment()
        },

        printPrepare(printer) {
            log('printPrepare => ', printer)

            // END WIZARD PROCESS SAVE TIME SPENT
            this.timetoPrintW.e = moment()
            const serverTimeDifference = this.$store.getters['getServerTimeDifference']

            var self = this
            if (!this.activePrint || printer.digitalState) {
                var now = moment().add(serverTimeDifference, 'minutes')
                var genericParams = {
                    employee: self.currentEmployee().initials,
                    employee_name: self.currentEmployee().name + ' ' + self.currentEmployee().surname,
                    product: printer.productName,
                    note: printer.autoDateCalc ? printer.oParams.note : printer.label.note ? printer.label.note : '',
                    note1: printer.autoDateCalc ? printer.oParams.note1 : printer.label.note1 ? printer.label.note1 : '',
                    note2: printer.autoDateCalc ? printer.oParams.note2 : printer.label.note2 ? printer.label.note2 : '',
                    note3: printer.autoDateCalc ? printer.oParams.note3 : printer.label.note3 ? printer.label.note3 : '',
                    print_date: printer.bUpdatedPrintDate ? printer.oParams.print_date + ' *' : now.format('DD/MM/YYYY HH:mm'),
                    print_date_day: printer.bUpdatedPrintDate ? printer.oParams.print_date_day : now.format('DD/MM/YYYY'),
                    print_date_time: printer.bUpdatedPrintDate ? printer.oParams.print_date_time : now.format('HH:mm'),
                    bUpdatedPrintDate: printer.bUpdatedPrintDate,
                    digitalState: printer.digitalState,
                    autoDateCalc: printer.autoDateCalc
                }

                //iterate printer.oParams and if key not exists in genericParams assign it, only if key doesn't contain 'date'
                Object.keys(printer.oParams).forEach((key) => {
                    if (!genericParams[key] && !key.includes('date') && !['state', 'quantity', 'cantidad'].includes(key)) {
                        genericParams[key] = printer.oParams[key]
                    }
                })

                if (genericParams.autoDateCalc) {
                    Object.keys(printer.oParams).forEach((key) => {
                        if (key.includes('_timestamp')) {
                            var keyAux = key.replace('_timestamp', '')
                            if (printer.oParams[keyAux]) {
                                genericParams[keyAux] = printer.oParams[keyAux]
                            }
                        } else if (key.includes('usodate')) {
                            genericParams[key] = printer.oParams[key]
                        } else if (key.match(/ingr.{1,2}date/gi) || key.match(/ingr.{1,2}rec/gi)) {
                            genericParams[key] = printer.oParams[key]
                        }
                    })
                }

                this.printQueue = this.printQueue.slice(1)

                var printDate = printer.bUpdatedPrintDate || printer.autoDateCalc ? printer.oParams.print_date : false
                if ((!printer.bUpdatedPrintDate && !printer.autoDateCalc) || printer.digitalState) {
                    const timezone = this.$store.getters['getTimeZoneName']
                    let date = moment()
                    try {
                        const options = {
                            timeZone: timezone,
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric'
                        }
                        const copy = moment(date)

                        log('1', date.format('DD/MM/YYYY HH:mm'))

                        const dateTimeFormat = new Intl.DateTimeFormat([], options).formatToParts()
                        const objectDate = {}
                        for (const item of dateTimeFormat) {
                            objectDate[item.type] = item.value
                        }
                        const dateString = `${objectDate['day'].padStart(2, '0')}/${objectDate['month'].padStart(2, '0')}/${objectDate['year']} ${objectDate['hour'].padStart(2, '0')}:${objectDate['minute'].padStart(2, '0')}`
                        date = moment(dateString, 'DD/MM/YYYY HH:mm')

                        log('2', date.format('DD/MM/YYYY HH:mm'))
                        log('3', date.diff(copy, 'minutes'))
                        log('4', serverTimeDifference)

                        if (date.diff(copy, 'minutes') != serverTimeDifference) {
                            const difference = serverTimeDifference - date.diff(copy, 'minutes')
                            date = date.add(difference, 'minutes')
                        }

                        printDate = date
                    } catch (error) {
                        logError(error)
                        printDate = moment()
                    }

                    self.print(printer.data, printer.processData, genericParams, printDate)
                } else {
                    self.print(printer.data, printer.processData, genericParams, printDate)
                }
            }
        },

        printCancel() {
            if(!this.collections[this.sSelectedCollection]) {
                if(Object.keys(this.collections).length > 0){
                    this.sSelectedCollection = Object.keys(this.collections)[0]
                } else {
                    this.unselectCollection()
                }
            }
            this.printQueue = []
            this.labelSelected = false
            this.aStatesToPrint = []
            this.$nextTick(function () {
                if (this.$refs.labelItems) {
                    this.$refs.labelItems.scrollToIndex(this.currentLabelIndex)
                }
            })
        },

        // oProcess -> Pasos del popup
        // oProduct -> Informacion del producto (label, selected state...)
        // defaultParams -> Valores por defecto (Empleado, F. impresion...)
        // printDate -> Fecha de impresion (para que en los next state sea 100pre la misma)
        print(oProcess, oProduct, defaultParams = {}, printDate = false) {
            this.timetoPrintP.i = moment()

            var self = this
            const serverTimeDifference = this.$store.getters['getServerTimeDifference']

            // console.log('print ...')

            // If we have configured the printer
            var current_config = this.$store.getters['printer/getCurrentPrinter']
            var currentPrinterConfig = this.$store.getters['printer/getPrinterConfig'](current_config)
            if (current_config) {
                // --- Definicion de variables
                // console.log('--- GO PRINT')

                self.activePrint = true // Inactivate button to print

                self.printData = [] // Data we'll print
                self.num_copies = 1
                self.next_state = false // Next state of selected state
                self.oParams = defaultParams

                var label = oProduct.label
                self.oParams.category = self.categories[label.category_id] ? self.categories[label.category_id].name : ''

                log(self.oParams)
                // console.log('print -> label', label)
                var states = label.states ? label.states : false
                var selState = oProduct.selectedState.id ? oProduct.selectedState.id : false
                var stateMsg = label.states[selState].msg
                var stateInfo = label.states[selState].info != null && label.states[selState].info != 'null' ? label.states[selState].info : {}

                var time = states[selState] && !isNaN(states[selState].time) ? parseInt(states[selState].time) : 0
                var preptime = states[selState] && !isNaN(states[selState].preptime) ? parseInt(states[selState].preptime) : 0

                var format = self.$store.getters['printer/getTemplates'](label.states[selState].format)
                log('INIT FORMAT ->', JSON.parse(JSON.stringify(format)))
                if (format.printer_id != currentPrinterConfig.model) {
                    var templates = this.$store.getters['printer/getTemplatesByPrinterModel'](currentPrinterConfig.model)
                    var bFound = false
                    for (var t in templates) {
                        var tpl = templates[t]
                        const label = this.$store.getters['printer/getLabels'](tpl.label_id)
                        log('tpl -> ', JSON.parse(JSON.stringify(tpl)))
                        log('label -> ', JSON.parse(JSON.stringify(label)))
                        if (tpl.parent_id == format.id && ((typeof currentPrinterConfig.templates[tpl.label_id] !== 'undefined' && currentPrinterConfig.platform == 'android') || label.tpl_file_app.includes('.z') || self.$store.getters['printer/getModels'](currentPrinterConfig.model)['sdk'] != 'brother' || !self.$store.getters.getIsApp)) {
                            format = tpl
                            bFound = true
                        }
                        //TODO bFound == FALSE -> SHOW ERROR
                    }
                }
                log('FINAL FORMAT ->', JSON.parse(JSON.stringify(format)))

                var formatValues = format ? JSON.parse(format.data) : []
                var newPreviewStructure = format.realistic_preview ? JSON.parse(format.realistic_preview) : false
                log('formatValues', formatValues)

                // --- Set de valores que ya conocemos

                self.oParams['state'] = states[selState].name
                self.next_state = states[selState].next
                var oLocation = self.$store.getters['loginUser/getLocalLocation']
                // console.log('LOCATION ->', oLocation)
                self.oParams['account'] = oLocation.account_name
                self.oParams['location'] = oLocation.name
                self.oParams['quantity'] = self.num_copies
                self.oParams['city'] = oLocation.city
                self.oParams['address'] = oLocation.address
                self.oParams['loc_state'] = oLocation.state
                self.oParams['cp'] = oLocation.cp

                for (var key in stateInfo) {
                    self.oParams[key] = stateInfo[key]
                }

                // --- Format data from process, and create auxiliary info (date_da, date_time...)
                var bCaducidadIsBarcode = false
                for (var i in oProcess) {
                    var step = oProcess[i]
                    log('step', step)

                    if (step.keyname == 'quantity') {
                        self.oParams[step.keyname] = step.result
                        self.num_copies = step.modified ? step.original : parseInt(step.result)
                    } else if (step.type == 'date' || step.type == 'datetime' || step.type == 'datepast' || step.type == 'datetimefree' || step.type == 'datefree' || step.type == 'barcode' || step.type == 'datetimepast') {
                        if (step.type == 'datetime' || step.type == 'datetimefree' || step.type == 'datefree' || step.type == 'datetimepast') {
                            // console.log(step.result)
                            step.result = moment(step.result, 'DD/MM/YYYY HH:mm')
                        } else if (step.type == 'barcode') {
                            if (step.keyname == 'caducidad') bCaducidadIsBarcode = true

                            if (step.result && step.result.trim() !== '') {
                                self.oParams[step.keyname] = step.result
                                if (step.result.toString().length == 10) {
                                    step.result = moment(step.result * 1000)
                                } else {
                                    step.result = moment(false)
                                }
                            }
                        } else if (step.result) {
                            // TODO CHECK IF THIS IS CORRECT
                            step.result = moment(step.result, 'DD/MM/YYYY HH:mm').endOf('day')
                            // step.result = moment(step.result, 'DD/MM/YYYY HH:mm')
                        }

                        // We create different variables (date, date_day...)
                        log(step.result)
                        let aux = step.result
                        if (aux && aux.isValid()) {
                            if (step.type !== 'barcode') self.oParams[step.keyname] = aux.format('DD/MM/YYYY HH:mm')
                            else self.oParams[step.keyname + '_datetime'] = aux.format('DD/MM/YYYY HH:mm')

                            self.oParams[step.keyname + '_day'] = aux.format('DD/MM/YYYY')
                            self.oParams[step.keyname + '_time'] = aux.format('HH:mm')
                            self.oParams[step.keyname + '_timestamp'] = parseInt(aux.valueOf() / 1000).toString()
                            self.oParams[step.keyname + '_d1'] = aux.isoWeekday() == 1 ? 'x' : ' '
                            self.oParams[step.keyname + '_d2'] = aux.isoWeekday() == 2 ? 'x' : ' '
                            self.oParams[step.keyname + '_d3'] = aux.isoWeekday() == 3 ? 'x' : ' '
                            self.oParams[step.keyname + '_d4'] = aux.isoWeekday() == 4 ? 'x' : ' '
                            self.oParams[step.keyname + '_d5'] = aux.isoWeekday() == 5 ? 'x' : ' '
                            self.oParams[step.keyname + '_d6'] = aux.isoWeekday() == 6 ? 'x' : ' '
                            self.oParams[step.keyname + '_d7'] = aux.isoWeekday() == 7 ? 'x' : ' '

                            for (let i = 0; i < 4; i++) {
                                let day = i18n.t('calendar.days')[aux.day()].toUpperCase()
                                if (i > 0) {
                                    self.oParams[step.keyname + '_wd' + i] = day.slice(0, i)
                                } else {
                                    self.oParams[step.keyname + '_wd'] = day
                                }
                            }
                        } else if (!self.oParams.autoDateCalc) {
                            if (step.type !== 'barcode' || step.result == '') self.oParams[step.keyname] = ''
                            else self.oParams[step.keyname + '_datetime'] = ''

                            self.oParams[step.keyname + '_day'] = ''
                            self.oParams[step.keyname + '_time'] = ''
                            self.oParams[step.keyname + '_timestamp'] = ''
                            self.oParams[step.keyname + '_d1'] = ' '
                            self.oParams[step.keyname + '_d2'] = ' '
                            self.oParams[step.keyname + '_d3'] = ' '
                            self.oParams[step.keyname + '_d4'] = ' '
                            self.oParams[step.keyname + '_d5'] = ' '
                            self.oParams[step.keyname + '_d6'] = ' '
                            self.oParams[step.keyname + '_d7'] = ' '

                            for (let i = 0; i < 4; i++) {
                                if (i > 0) {
                                    self.oParams[step.keyname + '_wd' + i] = ' '
                                } else {
                                    self.oParams[step.keyname + '_wd'] = ' '
                                }
                            }
                        }
                    } else {
                        // We set any other value in their position and result
                        self.oParams[step.keyname] = step.result
                    }
                }

                // Fecha de impresion de etiqueta
                printDate = printDate ? moment(printDate, 'DD/MM/YYYY HH:mm') : moment().add(serverTimeDifference, 'minutes')
                if (self.tpRound) {
                    printDate = printDate.roundTo('minute', self.tpRound)
                }
                self.oParams['print_date'] = printDate.format('DD/MM/YYYY HH:mm')
                self.oParams['print_date_day'] = printDate.format('DD/MM/YYYY')
                self.oParams['print_date_time'] = printDate.format('H:mm')
                self.oParams['print_date_d1'] = printDate.isoWeekday() == 1 ? 'x' : ' '
                self.oParams['print_date_d2'] = printDate.isoWeekday() == 2 ? 'x' : ' '
                self.oParams['print_date_d3'] = printDate.isoWeekday() == 3 ? 'x' : ' '
                self.oParams['print_date_d4'] = printDate.isoWeekday() == 4 ? 'x' : ' '
                self.oParams['print_date_d5'] = printDate.isoWeekday() == 5 ? 'x' : ' '
                self.oParams['print_date_d6'] = printDate.isoWeekday() == 6 ? 'x' : ' '
                self.oParams['print_date_d7'] = printDate.isoWeekday() == 7 ? 'x' : ' '
                self.oParams['print_date_timestamp'] = parseInt(printDate.valueOf() / 1000).toString()

                var currentDay = moment(moment().add(serverTimeDifference, 'minutes').format('DD/MM/YYYY 00:00'), 'DD/MM/YYYY HH:mm')
                self.oParams['currentday'] = currentDay.format('DD/MM/YYYY 00:00')
                self.oParams['currentday_day'] = currentDay.format('DD/MM/YYYY')
                self.oParams['currentday_time'] = currentDay.format('00:00')
                self.oParams['currentday_timestamp'] = parseInt(currentDay.valueOf() / 1000).toString()

                var currentTime = moment(moment().add(serverTimeDifference, 'minutes').format('DD/MM/YYYY HH:mm'), 'DD/MM/YYYY HH:mm')
                self.oParams['currenttime'] = currentTime.format('DD/MM/YYYY HH:mm')
                self.oParams['currenttime_day'] = currentTime.format('DD/MM/YYYY')
                self.oParams['currenttime_time'] = currentTime.format('HH:mm')
                self.oParams['currenttime_timestamp'] = parseInt(currentTime.valueOf() / 1000).toString()

                // Fecha de cáculo de caducidades
                log('self.oParams', Object.assign({}, self.oParams))
                var initDate = self.oParams['initdate'] ? moment(self.oParams['initdate'], 'DD/MM/YYYY HH:mm') : !self.oParams.autoDateCalc ? printDate : moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm')
                log('initDate', initDate)
                // Caducidad secundaria
                const cad2a = self.formatTimeToMax(time)
                let aux = !self.oParams.autoDateCalc ? moment(initDate).add(cad2a.result, cad2a.format) : moment(self.oParams['date'], 'DD/MM/YYYY HH:mm') // Print date + time
                if (self.tpRound) {
                    aux = aux.roundTo('minute', self.tpRound)
                }
                self.oParams['date'] = aux.format('DD/MM/YYYY HH:mm')
                self.oParams['date_day'] = aux.format('DD/MM/YYYY')
                self.oParams['date_time'] = aux.format('HH:mm')
                self.oParams['date_timestamp'] = parseInt(aux.valueOf() / 1000).toString()

                self.oParams['date_raw'] = aux.format('DD/MM/YYYY HH:mm')
                self.oParams['date_raw_day'] = aux.format('DD/MM/YYYY')
                self.oParams['date_raw_time'] = aux.format('HH:mm')
                self.oParams['date_raw_timestamp'] = parseInt(aux.valueOf() / 1000).toString()

                // Caducidad de preparacion (?)
                const freezingTime = self.formatTimeToMax(preptime)
                aux = moment(initDate).add(freezingTime.result, freezingTime.format) // Print date + preptime
                if (self.tpRound) {
                    aux = aux.roundTo('minute', self.tpRound)
                }
                self.oParams['prep_date'] = aux.format('DD/MM/YYYY HH:mm')
                self.oParams['prep_date_day'] = aux.format('DD/MM/YYYY')
                self.oParams['prep_date_time'] = aux.format('HH:mm')
                self.oParams['prep_date_timestamp'] = parseInt(aux.valueOf() / 1000).toString()

                // --- Set de valores que han de ser calculados pero que no los introduce
                //     el usuario, por tanto no estan en el process de por si
                for (var field in formatValues) {
                    var value = formatValues[field]

                    // si son formatValues y estan entre [ ]
                    const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                    let m

                    while ((m = regex.exec(value)) !== null) {
                        // This is necessary to avoid infinite loops with zero-width matches
                        if (m.index === regex.lastIndex) {
                            regex.lastIndex++
                        }

                        // The result can be accessed through the `m`-variable.
                        m.forEach((match, groupIndex) => {
                            match = match.replace(/[\{\}\[\]]/gi, '')
                            // console.log('MATCH: ', match)
                            if (match != '' && !Object.keys(self.oParams).includes(match)) {
                                self.oParams[match] = ''
                            }
                        })
                    }
                }

                // console.log('--- Setted variables')
                log('oParams', Object.assign({}, self.oParams))

                // console.log('--- Formatted process variables')
                // console.log(self.oParams)

                // --- Se calculan las caducidades y se hacen las debidas validaciones

                // Auxiliary vars
                let cadPrimaria = false
                if (bCaducidadIsBarcode) {
                    cadPrimaria = self.oParams['caducidad'] && self.oParams['caducidad_datetime'] ? moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm') : false
                } else {
                    cadPrimaria = self.oParams['caducidad'] && moment(self.oParams['caducidad'], 'DD/MM/YYYY HH:mm').isValid() ? moment(self.oParams['caducidad'], 'DD/MM/YYYY HH:mm') : false
                }

                if (cadPrimaria) {
                    self.oParams['caducidad_day'] = cadPrimaria.format('DD/MM/YYYY')
                    self.oParams['caducidad_time'] = cadPrimaria.format('HH:mm')
                    self.oParams['caducidad_timestamp'] = parseInt(cadPrimaria.valueOf() / 1000).toString()
                } else if (!bCaducidadIsBarcode) {
                    self.oParams['caducidad'] = ''
                    self.oParams['caducidad_day'] = ''
                    self.oParams['caducidad_time'] = ''
                    self.oParams['caducidad_timestamp'] = ''
                }
                // let cadSecundaria = moment(self.oParams['date']);
                // let cadPreparacion = moment(self.oParams['prep_date']);
                let cadSecundaria = moment(self.oParams['date'], 'DD/MM/YYYY HH:mm')
                let cadPreparacion = moment(self.oParams['prep_date'], 'DD/MM/YYYY HH:mm')

                // console.log('cadPrimaria + cadSecundaria + cadPreparacion')
                // console.log(cadPrimaria)
                // console.log(cadSecundaria)
                // console.log(cadPreparacion)

                // Si el producto caduca antes que la cad. secundaria, ponemos la cad primaria como caducidad
                if (cadPrimaria && cadPrimaria.isBefore(cadSecundaria)) {
                    self.oParams['date'] = cadPrimaria.format('DD/MM/YYYY HH:mm')
                    self.oParams['date_day'] = cadPrimaria.format('DD/MM/YYYY')
                    self.oParams['date_time'] = cadPrimaria.format('HH:mm')
                    self.oParams['date_timestamp'] = parseInt(cadPrimaria.valueOf() / 1000).toString()
                }
                // Lo mismo con el preptime
                if (cadPrimaria && cadPrimaria.isBefore(cadPreparacion)) {
                    self.oParams['date'] = cadPrimaria.format('DD/MM/YYYY HH:mm')
                    self.oParams['date_day'] = cadPrimaria.format('DD/MM/YYYY')
                    self.oParams['date_time'] = cadPrimaria.format('HH:mm')
                    self.oParams['date_timestamp'] = parseInt(cadPrimaria.valueOf() / 1000).toString()

                    // No tiene sentido un t. de preparacion si ya ha caducado, asi que lo quitamos
                    self.oParams['prep_date'] = '-'
                    self.oParams['prep_date_day'] = '-'
                    self.oParams['prep_date_time'] = '-'
                    self.oParams['prep_date_timestamp'] = ''
                }

                // It only applies at next state
                // Si la cad. secundaria calculada es anterior a la fecha de impresion, es que ha caducado
                if (cadSecundaria.isSameOrBefore(initDate)) {
                    self.oParams['date'] = self.$t('label.expired')
                    self.oParams['date_day'] = self.$t('label.expired')
                    self.oParams['date_time'] = '--'
                    self.oParams['date_timestamp'] = ''
                }

                // Si el estado tiene un mensaje definido, lo mostramos
                if (stateMsg) {
                    self.oParams['date'] = stateMsg
                    self.oParams['date_day'] = stateMsg
                    self.oParams['date_time'] = '--'
                    self.oParams['date_timestamp'] = ''
                }

                // console.log('DATE IS VALID? ', self.oParams['date'])
                if (moment(self.oParams['date'], 'DD/MM/YYYY HH:mm').isValid()) {
                    self.oParams['date_d1'] = moment(self.oParams['date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 1 ? 'x' : ' '
                    self.oParams['date_d2'] = moment(self.oParams['date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 2 ? 'x' : ' '
                    self.oParams['date_d3'] = moment(self.oParams['date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 3 ? 'x' : ' '
                    self.oParams['date_d4'] = moment(self.oParams['date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 4 ? 'x' : ' '
                    self.oParams['date_d5'] = moment(self.oParams['date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 5 ? 'x' : ' '
                    self.oParams['date_d6'] = moment(self.oParams['date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 6 ? 'x' : ' '
                    self.oParams['date_d7'] = moment(self.oParams['date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 7 ? 'x' : ' '
                } else {
                    self.oParams['date_d1'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 1 ? 'x' : ' '
                    self.oParams['date_d2'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 2 ? 'x' : ' '
                    self.oParams['date_d3'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 3 ? 'x' : ' '
                    self.oParams['date_d4'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 4 ? 'x' : ' '
                    self.oParams['date_d5'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 5 ? 'x' : ' '
                    self.oParams['date_d6'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 6 ? 'x' : ' '
                    self.oParams['date_d7'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 7 ? 'x' : ' '

                    self.oParams['print_date_d1'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 1 ? 'x' : ' '
                    self.oParams['print_date_d2'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 2 ? 'x' : ' '
                    self.oParams['print_date_d3'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 3 ? 'x' : ' '
                    self.oParams['print_date_d4'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 4 ? 'x' : ' '
                    self.oParams['print_date_d5'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 5 ? 'x' : ' '
                    self.oParams['print_date_d6'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 6 ? 'x' : ' '
                    self.oParams['print_date_d7'] = moment(self.oParams['print_date'], 'DD/MM/YYYY HH:mm').isoWeekday() == 7 ? 'x' : ' '
                }

                let dateParams = ['print_date', 'currentday', 'currenttime', 'date', 'prep_date']
                dateParams.forEach((date) => {
                    for (let i = 0; i < 4; i++) {
                        aux = moment(self.oParams[date], 'DD/MM/YYYY HH:mm')
                        if (aux.isValid()) {
                            let day = i18n.t('calendar.days')[aux.day()].toUpperCase()
                            if (i > 0) {
                                self.oParams[date + '_wd' + i] = day.slice(0, i)
                            } else {
                                self.oParams[date + '_wd'] = day
                            }
                        } else {
                            // IN CASE INVALID DATE, GENERATE DAY INFO WITH CURRENT DAY
                            let day = i18n.t('calendar.days')[moment().day()].toUpperCase()
                            if (i > 0) {
                                self.oParams[date + '_wd' + i] = day.slice(0, i)
                            } else {
                                self.oParams[date + '_wd'] = day
                            }
                        }
                    }
                })

                // console.log('--- Calc de logicas generales (Caducado...)')
                // console.log(self.oParams)

                // --- Calc de valores custom de cada restaurante
                let bBucleAmasada = false
                let bBlucleAmasadaFinish = false
                for (var key in self.oParams) {
                    var val = self.oParams[key]
                    let keyUsoDate = false
                    if (key.match(/usodate/)) {
                        keyUsoDate = key
                        key = 'usodate'
                    }

                    switch (key) {
                        // PIZZA HUT HACK -----------------------
                        case 'cad_sec_mesa':
                        case 'cad_sec_mesa1':
                            if (initDate.isSame(cadPrimaria, 'day')) {
                                self.oParams[key] = cadPrimaria.format('DD/MM/YYYY HH:mm')
                            } else {
                                self.oParams[key] = initDate.format('DD/MM/YYYY') + i18n.t('label.print.config.close_day')
                            }
                            break

                        case 'cad_sec_mesa2':
                            if (cadSecundaria.isSame(cadPrimaria, 'day') || cadSecundaria.isAfter(cadPrimaria, 'day')) {
                                self.oParams[key] = cadPrimaria.format('DD/MM/YYYY HH:mm')
                            } else {
                                self.oParams[key] = cadSecundaria.format('DD/MM/YYYY') + i18n.t('label.print.config.close_day')
                            }
                            break
                        case 'caducidad2': //SALSA TOMATE - CADUCIDAD 1ª ESPECIAS
                            var stepCad2 = oProcess.filter((step) => {
                                // console.log(index, step)
                                return step.keyname == 'caducidad2'
                            })
                            stepCad2 = stepCad2[0] ? stepCad2[0] : false
                            let caducidad2 = stepCad2 && stepCad2.type == 'barcode' ? moment(self.oParams['caducidad2_datetime'], 'DD/MM/YYYY HH:mm') : moment(self.oParams['caducidad2'], 'DD/MM/YYYY HH:mm')
                            // console.log('Caducidad2 - ', cadPrimaria.valueOf(), caducidad2.valueOf(), cadSecundaria.valueOf())
                            if (cadPrimaria.valueOf() > caducidad2.valueOf() && (caducidad2.valueOf() < cadSecundaria.valueOf() || (stateMsg && caducidad2.valueOf() < moment(cadSecundaria.format('DD/MM/YYYY 23:59'), 'DD/MM/YYYY HH:mm').valueOf()))) {
                                cadSecundaria = caducidad2
                                self.oParams['date'] = caducidad2.format('DD/MM/YYYY HH:mm')
                                self.oParams['date_day'] = caducidad2.format('DD/MM/YYYY')
                                self.oParams['date_time'] = caducidad2.format('HH:mm')
                                self.oParams['date_timestamp'] = parseInt(caducidad2.valueOf() / 1000).toString()
                            }
                            if (cadPrimaria && cadPrimaria.isValid() && caducidad2.isValid() && stepCad2.rules && stepCad2.rules.o && stepCad2.rules.pa && stepCad2.rules.v && self.oParams[stepCad2.rules.pa]) {
                                let date_compare = moment()
                                switch (stepCad2.rules.v) {
                                    case 'end_day':
                                        date_compare = moment(printDate.format('DD/MM/YYYY 23:59'), 'DD/MM/YYYY HH:mm')
                                        break

                                    default:
                                        break
                                }
                                switch (stepCad2.rules.o) {
                                    case '<':
                                        self.oParams['caducidad2'] = caducidad2.isBefore(date_compare) ? self.oParams[stepCad2.rules.pa] : caducidad2.format(stepCad2.rules.vformat)
                                        break
                                    case '<=':
                                        self.oParams['caducidad2'] = caducidad2.isSameOrBefore(date_compare) ? self.oParams[stepCad2.rules.pa] : caducidad2.format(stepCad2.rules.vformat)
                                        break
                                    case '>':
                                        self.oParams['caducidad2'] = caducidad2.isAfter(date_compare) ? self.oParams[stepCad2.rules.pa] : caducidad2.format(stepCad2.rules.vformat)
                                        break
                                    case '>=':
                                        self.oParams['caducidad2'] = caducidad2.isSameOrAfter(date_compare) ? self.oParams[stepCad2.rules.pa] : caducidad2.format(stepCad2.rules.vformat)
                                        break
                                }
                            }
                            break
                        // PIZZA HUT HACK -----------------------
                        case 'df_lev_massa_day':
                            if (moment(self.oParams['dflevedacao'], 'DD/MM/YYYY').isValid()) {
                                let df = moment(self.oParams['dflevedacao'], 'DD/MM/YYYY')
                                let dfMassa = [false, false, 3, 2, 1, false, false]
                                let diff = moment(initDate).diff(df, 'days')
                                let df_lev_massa = moment(initDate).add(dfMassa[diff], 'h')
                                // console.log(diff, dfMassa[diff])

                                self.oParams['df_lev_massa_day'] = df_lev_massa.format('DD/MM/YYYY')
                                self.oParams['df_lev_massa_time'] = df_lev_massa.format('HH:mm')
                                self.oParams['df_lev_massa_timestamp'] = parseInt(df_lev_massa.valueOf() / 1000).toString()
                            } else {
                                self.oParams['df_lev_massa_day'] = ''
                                self.oParams['df_lev_massa_time'] = ''
                                self.oParams['df_lev_massa_timestamp'] = ''
                            }
                            break
                        case 'df_lev_sides_day':
                            if (moment(self.oParams['dflevedacao'], 'DD/MM/YYYY').isValid()) {
                                let df = moment(self.oParams['dflevedacao'], 'DD/MM/YYYY')
                                let dfMassa = [false, false, 3, 2, 1, false, false]
                                let diff = moment(initDate).diff(df, 'days')
                                let df_lev_sides = moment(initDate).add(dfMassa[diff], 'h')
                                // console.log(diff, dfMassa[diff])

                                self.oParams['df_lev_sides_day'] = df_lev_sides.format('DD/MM/YYYY')
                                self.oParams['df_lev_sides_time'] = df_lev_sides.format('HH:mm')
                                self.oParams['df_lev_sides_time'] = df_lev_sides.valueOf()
                            } else {
                                self.oParams['df_lev_sides_day'] = ''
                                self.oParams['df_lev_sides_time'] = ''
                                self.oParams['df_lev_sides_timestamp'] = ''
                            }
                            break
                        case 'df_ta_massa_day':
                            if (moment(self.oParams['dftpambiente'], 'DD/MM/YYYY').isValid()) {
                                let df = moment(self.oParams['dftpambiente'], 'DD/MM/YYYY')
                                let dfMassa = [false, false, 4, 3, 2, 1, 0.5]
                                let diff = moment(initDate).diff(df, 'days')
                                let df_ta_massa = moment(initDate).add(dfMassa[diff], 'h')
                                // console.log(diff, dfMassa[diff], initDate)

                                self.oParams['df_ta_massa_day'] = df_ta_massa.format('DD/MM/YYYY')
                                self.oParams['df_ta_massa_time'] = df_ta_massa.format('HH:mm')
                                self.oParams['df_ta_massa_timestamp'] = parseInt(df_ta_massa.valueOf() / 1000).toString()
                            } else {
                                self.oParams['df_ta_massa_day'] = ''
                                self.oParams['df_ta_massa_time'] = ''
                                self.oParams['df_ta_massa_timestamp'] = ''
                            }
                            break
                        case 'df_ta_sides_day':
                            if (moment(self.oParams['dftpambiente'], 'DD/MM/YYYY').isValid()) {
                                let df = moment(self.oParams['dftpambiente'], 'DD/MM/YYYY')
                                let dfMassa = [false, false, 2, 1.5, 1, 0.5, 0.25]
                                let diff = moment(initDate).diff(df, 'days')
                                let df_ta_sides = moment(initDate).add(dfMassa[diff], 'h')
                                // console.log(diff, dfMassa[diff])

                                self.oParams['df_ta_sides_day'] = df_ta_sides.format('DD/MM/YYYY')
                                self.oParams['df_ta_sides_time'] = df_ta_sides.format('HH:mm')
                                self.oParams['df_ta_sides_timestamp'] = parseInt(df_ta_sides.valueOf() / 1000).toString()
                            } else {
                                self.oParams['df_ta_sides_day'] = ''
                                self.oParams['df_ta_sides_time'] = ''
                                self.oParams['df_ta_sides_timestamp'] = ''
                            }
                            break
                        case 'df_lev_pan_day':
                            if (moment(self.oParams['dflevedacao0'], 'DD/MM/YYYY').isValid()) {
                                let df = moment(self.oParams['dflevedacao0'], 'DD/MM/YYYY')
                                let dfMassa = [2.5, 1.5, 1, false, false, false, false]
                                let diff = moment(initDate).diff(df, 'days')
                                let df_lev_pan = moment(initDate).add(dfMassa[diff], 'h')
                                // console.log(diff, dfMassa[diff])

                                self.oParams['df_lev_pan_day'] = df_lev_pan.format('DD/MM/YYYY')
                                self.oParams['df_lev_pan_time'] = df_lev_pan.format('HH:mm')
                                self.oParams['df_lev_pan_timestamp'] = parseInt(df_lev_pan.valueOf() / 1000).toString()
                            } else {
                                self.oParams['df_lev_pan_day'] = ''
                                self.oParams['df_lev_pan_time'] = ''
                                self.oParams['df_lev_pan_timestamp'] = ''
                            }
                            break
                        case 'df_ta_pan_day':
                            if (moment(self.oParams['dftpambiente0'], 'DD/MM/YYYY').isValid()) {
                                let df = moment(self.oParams['dftpambiente0'], 'DD/MM/YYYY')
                                let dfMassa = [4, 4, 3, 3, 2, 2, false]
                                let diff = moment(initDate).diff(df, 'days')
                                let df_ta_pan = moment(initDate).add(dfMassa[diff], 'h')
                                // console.log(diff, dfMassa[diff])

                                self.oParams['df_ta_pan_day'] = df_ta_pan.format('DD/MM/YYYY')
                                self.oParams['df_ta_pan_time'] = df_ta_pan.format('HH:mm')
                                self.oParams['df_ta_pan_timestamp'] = parseInt(df_ta_pan.valueOf() / 1000).toString()
                            } else {
                                self.oParams['df_ta_pan_day'] = ''
                                self.oParams['df_ta_pan_time'] = ''
                                self.oParams['df_ta_pan_timestamp'] = ''
                            }
                            break
                        case 'usodate':
                            if ((self.oParams['caducidad_datetime'] && self.oParams['caducidad_datetime'] != '' && self.oParams['caducidad2_datetime'] && self.oParams['caducidad2_datetime'] != '') || (!cadPrimaria && self.oParams['caducidad2_datetime'] && self.oParams['caducidad2_datetime'] != '')) {
                                console.log('ENTRO A USO DATE', self.oParams[keyUsoDate], self.oParams['caducidad_datetime'], self.oParams['caducidad2_datetime'])
                                self.oParams[keyUsoDate] = moment(self.oParams['caducidad2_datetime'], 'DD/MM/YYYY HH:mm').add(preptime, 'minutes').format('DD/MM/YYYY HH:mm')
                            } else if (self.oParams['caducidad_datetime'] && typeof self.oParams['caducidad2'] == 'undefined' && self.oParams[keyUsoDate] == '') {
                                console.log('ENTRO A USO DATE CON SOLO caducidad')
                                self.oParams[keyUsoDate] = moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm').add(preptime, 'minutes').format('DD/MM/YYYY HH:mm')
                            } else if (self.oParams[keyUsoDate] != '' && (self.oParams['caducidad_datetime'] == '' || self.oParams['caducidad2_datetime'] == '')) {
                                self.oParams[keyUsoDate] = ''
                            }

                            if (self.oParams[keyUsoDate] != '') {
                                var alseaCad2Calc = moment(printDate.valueOf())
                                var bDateModify = false
                                if (time == 0 && stateMsg != '' && stateMsg != null) {
                                    alseaCad2Calc = moment(alseaCad2Calc.format('DD/MM/YYYY') + ' 23:59:59', 'DD/MM/YYYY HH:mm:ss')
                                    if (alseaCad2Calc.isAfter(moment(self.oParams['caducidad2_datetime'], 'DD/MM/YYYY HH:mm'))) {
                                        alseaCad2Calc = moment(self.oParams['caducidad2_datetime'], 'DD/MM/YYYY HH:mm')
                                        bDateModify = true
                                    }
                                    if (alseaCad2Calc.isAfter(moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm'))) {
                                        alseaCad2Calc = moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm')
                                        bDateModify = true
                                    }

                                    self.oParams['a_date_calc'] = !bDateModify ? stateMsg : alseaCad2Calc.format('DD/MM/YYYY HH:mm')
                                    self.oParams['a_date_calc_day'] = alseaCad2Calc.format('DD/MM/YYYY')
                                    self.oParams['a_date_calc_time'] = alseaCad2Calc.format('HH:mm')
                                    self.oParams['a_date_calc_timestamp'] = parseInt(alseaCad2Calc.valueOf() / 1000).toString()
                                    self.oParams['a_date_calc_d1'] = alseaCad2Calc.isoWeekday() == 1 ? 'x' : ' '
                                    self.oParams['a_date_calc_d2'] = alseaCad2Calc.isoWeekday() == 2 ? 'x' : ' '
                                    self.oParams['a_date_calc_d3'] = alseaCad2Calc.isoWeekday() == 3 ? 'x' : ' '
                                    self.oParams['a_date_calc_d4'] = alseaCad2Calc.isoWeekday() == 4 ? 'x' : ' '
                                    self.oParams['a_date_calc_d5'] = alseaCad2Calc.isoWeekday() == 5 ? 'x' : ' '
                                    self.oParams['a_date_calc_d6'] = alseaCad2Calc.isoWeekday() == 6 ? 'x' : ' '
                                    self.oParams['a_date_calc_d7'] = alseaCad2Calc.isoWeekday() == 7 ? 'x' : ' '
                                } else {
                                    const alcad2a = self.formatTimeToMax(time)
                                    alseaCad2Calc = alseaCad2Calc.add(alcad2a.result, alcad2a.format)

                                    if (alseaCad2Calc.isAfter(moment(self.oParams['caducidad2_datetime'], 'DD/MM/YYYY HH:mm'))) {
                                        alseaCad2Calc = moment(self.oParams['caducidad2_datetime'], 'DD/MM/YYYY HH:mm')
                                    }
                                    if (alseaCad2Calc.isAfter(moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm'))) {
                                        alseaCad2Calc = moment(self.oParams['caducidad_datetime'], 'DD/MM/YYYY HH:mm')
                                    }
                                    self.oParams['a_date_calc'] = alseaCad2Calc.format('DD/MM/YYYY HH:mm')
                                    self.oParams['a_date_calc_day'] = alseaCad2Calc.format('DD/MM/YYYY')
                                    self.oParams['a_date_calc_time'] = alseaCad2Calc.format('HH:mm')
                                    self.oParams['a_date_calc_timestamp'] = parseInt(alseaCad2Calc.valueOf() / 1000).toString()
                                    self.oParams['a_date_calc_d1'] = alseaCad2Calc.isoWeekday() == 1 ? 'x' : ' '
                                    self.oParams['a_date_calc_d2'] = alseaCad2Calc.isoWeekday() == 2 ? 'x' : ' '
                                    self.oParams['a_date_calc_d3'] = alseaCad2Calc.isoWeekday() == 3 ? 'x' : ' '
                                    self.oParams['a_date_calc_d4'] = alseaCad2Calc.isoWeekday() == 4 ? 'x' : ' '
                                    self.oParams['a_date_calc_d5'] = alseaCad2Calc.isoWeekday() == 5 ? 'x' : ' '
                                    self.oParams['a_date_calc_d6'] = alseaCad2Calc.isoWeekday() == 6 ? 'x' : ' '
                                    self.oParams['a_date_calc_d7'] = alseaCad2Calc.isoWeekday() == 7 ? 'x' : ' '
                                }

                                let iMarginTime = keyUsoDate.replace('usodate', '')
                                let bInMarginTime = true
                                if (iMarginTime != '') {
                                    bInMarginTime = printDate.isBefore(moment(self.oParams[keyUsoDate], 'DD/MM/YYYY HH:mm').add(iMarginTime, 'minutes'))
                                }

                                if (printDate.isBefore(moment(self.oParams[keyUsoDate], 'DD/MM/YYYY HH:mm')) || !bInMarginTime || (bDateModify && printDate.isAfter(alseaCad2Calc)) || alseaCad2Calc.isBefore(printDate)) {
                                    self.$snackbar.error({
                                        message: i18n.t('tooltip.print_date_out_range'),
                                        closeable: true
                                    })
                                    self.current_error = true
                                }
                            } else {
                                self.oParams['a_date_calc'] = ''
                                self.oParams['a_date_calc_day'] = ''
                                self.oParams['a_date_calc_time'] = ''
                                self.oParams['a_date_calc_timestamp'] = ''
                                self.oParams['a_date_calc_d1'] = ' '
                                self.oParams['a_date_calc_d2'] = ' '
                                self.oParams['a_date_calc_d3'] = ' '
                                self.oParams['a_date_calc_d4'] = ' '
                                self.oParams['a_date_calc_d5'] = ' '
                                self.oParams['a_date_calc_d6'] = ' '
                                self.oParams['a_date_calc_d7'] = ' '
                            }
                            break
                        case 'amasada': //DP Obrador logic
                            if (self.oParams['amasada'] && self.oParams['quantity'] > 0) {
                                bBucleAmasada = true
                                self.oParams['amasada'] = parseInt(self.oParams['amasada']) + (self.num_copies - parseInt(self.oParams['quantity']))

                                self.oParams['quantity'] = parseInt(self.oParams['quantity']) - 1
                                self.num_copies = 1

                                oProcess.forEach((step) => {
                                    if (step.keyname == 'quantity') {
                                        if (!step.modified) {
                                            step.modified = true
                                            step.original = step.result
                                        }
                                        step.result = self.oParams['quantity']
                                    }
                                })
                            } else {
                                bBlucleAmasadaFinish = true
                            }
                            break
                        default:
                            if (key.match(/ingr.{1,2}date$/gi) && self.oParams[key] != '') {
                                let caducidadIngr = moment(self.oParams[key], 'DD/MM/YYYY HH:mm')
                                if ((cadPrimaria.valueOf() > caducidadIngr.valueOf() || !cadPrimaria) && caducidadIngr.valueOf() < cadSecundaria.valueOf()) {
                                    cadSecundaria = caducidadIngr
                                    self.oParams['date'] = caducidadIngr.format('DD/MM/YYYY HH:mm')
                                    self.oParams['date_day'] = caducidadIngr.format('DD/MM/YYYY')
                                    self.oParams['date_time'] = caducidadIngr.format('HH:mm')
                                    self.oParams['date_timestamp'] = parseInt(caducidadIngr.valueOf() / 1000).toString()

                                    let day = i18n.t('calendar.days')[caducidadIngr.day()].toUpperCase()
                                    for (let i = 0; i < 4; i++) {
                                        if (i > 0) {
                                            self.oParams['date_wd' + i] = day.slice(0, i)
                                        } else {
                                            self.oParams['date_wd'] = day
                                        }
                                    }
                                }
                            }
                            break
                    }
                }

                // console.log('--- Calcs propios de restaurantes')
                // console.log(self.oParams)
                // console.log(formatValues)

                // --- Seteamos los datos que iran al template de la etiqueta
                for (var field in formatValues) {
                    var value = formatValues[field]

                    if (value) {
                        let aux = value

                        let start = aux.indexOf('[') > -1 ? aux.indexOf('[') : aux.indexOf('{')
                        start++
                        let end = aux.indexOf(']') > -1 ? aux.indexOf(']') : aux.indexOf('}')
                        let keyValue = aux.substring(start, end)
                        let oldKeyValue = aux.substring(start, end)

                        while (keyValue) {
                            // console.log('KEYVALUE: ', keyValue)
                            keyValue = keyValue.replace('[', '').replace(']', '').replace('{', '').replace('}', '')
                            aux = aux.replace('[', '').replace(']', '').replace('{', '').replace('}', '')

                            // console.log(keyValue, aux, Object.assign({}, self.oParams), self.oParams[keyValue])
                            // if (!self.oParams[keyValue]) {
                            //     self.oParams[keyValue] = aux
                            // }
                            // console.log(keyValue, aux, Object.assign({}, self.oParams), self.oParams[keyValue])

                            // let newValue = self.oParams[keyValue] // cleanString(self.oParams[keyValue])
                            // if (newValue.indexOf(oldKeyValue) != -1) {
                            //     newValue = newValue.replace(oldKeyValue, '')
                            //     self.oParams[keyValue] = newValue
                            // }
                            aux = aux.replace(keyValue, self.oParams[keyValue])

                            const formats = ['DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY HH:mm', 'DD/MM/YYYY']
                            if (newPreviewStructure) {
                                newPreviewStructure.fields.forEach((el) => {
                                    if (el.f_id && el.f_id == field) {
                                        if (el.format) {
                                            var bConverted = false
                                            formats.forEach((formatValidate) => {
                                                // Crear expresión regular basada en el formato
                                                let formatRegex = formatValidate.replace('DD', '\\d{2}').replace('MM', '\\d{2}').replace('YYYY', '\\d{4}').replace('HH', '\\d{2}').replace('mm', '\\d{2}').replace('ss', '\\d{2}').replace(/\s/g, '\\s')
                                                console.log('FORMAT REGEX --> ', formatRegex)
                                                const regex = new RegExp(`^.*${formatRegex}.*$`)
                                                console.log('REGEX --> ', regex)
                                                if (regex.test(aux) && !bConverted) {
                                                    console.log('FORMAT --> ', formatValidate, aux)
                                                    var aux2 = aux.replace(moment(aux, formatValidate).format(formatValidate), '{date}')
                                                    console.log('2 --> ', aux2)
                                                    var date = moment(aux, formatValidate).format(el.format)
                                                    console.log('DATE --> ', date)
                                                    aux = aux2.replace('{date}', date)
                                                    bConverted = true
                                                }
                                            })
                                            // value = moment(moment(value, 'DD/MM/YYYY HH:mm').format(el.format), el.format).isValid() ? moment(value, 'DD/MM/YYYY HH:mm').format(el.format) : value
                                            // aux = moment(moment(aux, 'DD/MM/YYYY HH:mm').format(el.format), el.format).isValid() ? moment(aux, 'DD/MM/YYYY HH:mm').format(el.format) : aux
                                        }
                                    } else if (el.value == value && el.format) {
                                        var bConverted = false
                                        formats.forEach((formatValidate) => {
                                        // Crear expresión regular basada en el formato
                                            let formatRegex = formatValidate.replace('DD', '\\d{2}').replace('MM', '\\d{2}').replace('YYYY', '\\d{4}').replace('HH', '\\d{2}').replace('mm', '\\d{2}').replace('ss', '\\d{2}').replace(/\s/g, '\\s')
                                            console.log('FORMAT REGEX --> ', formatRegex)
                                            const regex = new RegExp(`^.*${formatRegex}.*$`)
                                            console.log('REGEX --> ', regex)
                                            if (regex.test(aux) && !bConverted) {
                                                console.log('FORMAT --> ', formatValidate, aux)
                                                var aux2 = aux.replace(moment(aux, formatValidate).format(formatValidate), '{date}')
                                                console.log('2 --> ', aux2)
                                                var date = moment(aux, formatValidate).format(el.format)
                                                console.log('DATE --> ', date)
                                                aux = aux2.replace('{date}', date)
                                                bConverted = true
                                            }
                                        })
                                        // aux = moment(moment(aux, 'DD/MM/YYYY HH:mm').format(el.format), el.format).isValid() ? moment(aux, 'DD/MM/YYYY HH:mm').format(el.format) : aux
                                    }
                                })
                            }

                            if (['print_date', 'print_date_time', 'print_date_day'].includes(keyValue) && defaultParams.bUpdatedPrintDate) {
                                aux += ' *'
                            }

                            start = aux.indexOf('[') > -1 ? aux.indexOf('[') : aux.indexOf('{')
                            start++
                            end = aux.indexOf(']') > -1 ? aux.indexOf(']') : aux.indexOf('}')
                            keyValue = aux.substring(start, end)
                            oldKeyValue = aux.substring(start, end)
                        }
                        var data = aux // cleanString(aux)
                        data = data.trim() == '' ? ' ' : data
                        self.printData.push({ k: field, v: data })
                    } else {
                        self.printData.push({ k: field, v: ' ' })
                    }
                }

                // -- Mostraremos en el preview los mismos valores que se quieren imprimir
                let preview = {}
                for (var field in formatValues) {
                    var value = formatValues[field]

                    if (value.indexOf('[') != -1) {
                        let start = value.indexOf('[')
                        let end = value.indexOf(']')
                        let keyValue = value.substring(start, end).replace('[', '').replace(']', '')

                        let name = self.$t('label.preview.' + keyValue)
                        if (name.indexOf('label.preview.') != -1) {
                            name = keyValue
                        }

                        if (keyValue != '' && !Object.keys(preview).includes(keyValue) && Object.keys(self.oParams).includes(keyValue)) {
                            preview[keyValue] = {
                                label: name,
                                value: self.oParams[keyValue]
                            }
                        }
                    }
                }

                self.activePrint = false

                console.log('state', label.states[selState])
                if (label.states[selState].type == 3) {
                    let params = {
                        state: selState,
                        product: oProduct.label.id,
                        quantity: self.num_copies,
                        data: JSON.stringify(self.oParams),
                        platform: process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2
                    }

                    self.$store.dispatch('timerproduct/addTimer', params).then((response) => {
                        // let msg = i18n.tc('config.printer.success_save', parseInt(self.num_copies))
                        // if (parseInt(self.num_copies) > 1) {
                        //     msg = msg.replace('[num_copies]', self.num_copies)
                        // }
                        // msg = msg.replace('[product]', oProduct.label.name)
                        // self.$snackbar.progress({
                        //     closeable: true,
                        //     message: msg
                        // })
                    })
                }

                if (defaultParams.digitalState) {
                    let params = {
                        state: selState,
                        product: oProduct.label.id,
                        quantity: self.num_copies,
                        data: JSON.stringify(self.oParams),
                        platform: process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2
                    }
                    log('SEND INFO LABEL PRINTED')
                    self.timetoPrintP.e = moment()

                    params.timeToPrintW = self.timetoPrintW.e.diff(self.timetoPrintW.i)
                    if (self.isApp) params.timeToPrintP = self.timetoPrintP.e.diff(self.timetoPrintP.i)

                    self.$store.dispatch('label/sendLabelPrint', params).then((response) => {
                        let msg = i18n.tc('config.printer.success_save', parseInt(self.num_copies))
                        if (parseInt(self.num_copies) > 1) {
                            msg = msg.replace('[num_copies]', self.num_copies)
                        }
                        msg = msg.replace('[product]', oProduct.label.name)
                        self.$snackbar.progress({
                            closeable: true,
                            message: msg
                        })
                    })

                    self.activePrint = false

                    self.printCancel()
                } else {
                    if (self.isApp) {
                        if (!bBlucleAmasadaFinish) {
                            let params = {
                                state: selState,
                                product: oProduct.label.id,
                                quantity: self.num_copies,
                                data: JSON.stringify(self.oParams),
                                platform: 1,
                                template: format.id,
                                label: format.label_id,
                                printData: self.printData,
                                status: 0
                            }

                            self.$store.dispatch('label/addPrintLabelLogAndroid', params).then((response) => {
                                self.$snackbar.info({
                                    closeable: true,
                                    message: i18n.t('config.printer.success_queue_add', { product: oProduct.label.name })
                                })
                                self.labelQueue() // Update pending labels message

                                if (self.next_state && label.states[self.next_state]) {
                                    // console.log(' ---> Next state ' + oProduct.selectedState.name)
                                    this.printNextState(oProcess, oProduct, label)
                                } else if (bBucleAmasada) {
                                    this.printNextAmasada(oProcess, oProduct, label)
                                } else {
                                    self.doPrintQueue()
                                }
                            })
                        } else {
                            self.doPrintQueue()
                        }
                    } else {
                        // clearInterval(this.iIntervalStatusPrinter)
                        if (!bBlucleAmasadaFinish) {
                            self.$store
                                .dispatch('printer/printLabel', {
                                    printData: self.printData,
                                    num_copies: self.num_copies,
                                    context: {
                                        config: current_config,
                                        printer: currentPrinterConfig.model,
                                        template: format.id,
                                        label: format.label_id
                                    }
                                })
                                .then(function (response) {
                                    // console.log('LABEL.vue')
                                    if (self.next_state && label.states[self.next_state]) {
                                        // console.log(' ---> Next state ' + oProduct.selectedState.name)
                                        self.printNextState(oProcess, oProduct, label)
                                    } else if (bBucleAmasada) {
                                        self.printNextAmasada(oProcess, oProduct, label)
                                    } else {
                                        // console.log(' ---> Next state not defined')
                                        self.activePrint = false
                                        self.printQueue.shift()
                                        if (self.printQueue.length > 0) {
                                            self.printPopup()
                                        } else {
                                            // self.labelSelected = false
                                        }
                                    }

                                    var params = {
                                        state: selState,
                                        product: oProduct.label.id,
                                        quantity: self.num_copies,
                                        data: JSON.stringify(self.oParams),
                                        platform: process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2
                                    }
                                    log('SEND INFO LABEL PRINTED')
                                    self.timetoPrintP.e = moment()

                                    params.timeToPrintW = self.timetoPrintW.e.diff(self.timetoPrintW.i)
                                    if (self.isApp) params.timeToPrintP = self.timetoPrintP.e.diff(self.timetoPrintP.i)
                                    self.$store.dispatch('label/sendLabelPrint', params)

                                    if (!self.isApp) {
                                        var msg = process.env.VUE_APP_IS_APP == 'TRUE' ? i18n.tc('config.printer.success_print', parseInt(self.num_copies)) : i18n.tc('config.printer.success_window_print', parseInt(self.num_copies))
                                        if (parseInt(self.num_copies) > 1) {
                                            msg = msg.replace('[num_copies]', self.num_copies)
                                        }
                                        msg = msg.replace('[product]', oProduct.label.name)
                                        self.$snackbar.progress({
                                            closeable: true,
                                            message: msg
                                        })
                                    }
                                })
                                .catch(function (error) {
                                    log('Catch - error --> ', error)

                                    var msg = i18n.tc('config.printer.error_print', parseInt(self.num_copies))
                                    if (typeof msg == 'string') {
                                        if (parseInt(self.num_copies) > 1) {
                                            msg = msg.replace('[num_copies]', self.num_copies)
                                        }
                                        log(typeof msg, msg)
                                        msg = msg.replace('[product]', oProduct.label.name)

                                        const platform = process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2
                                        const appVersion = process.env.VUE_APP_VERSION

                                        if (self.printerError) {
                                            self.$store.dispatch('label/addPrintError', {
                                                error: self.printerError,
                                                platform: platform,
                                                version: appVersion
                                            })
                                        } else {
                                            self.bSendPrintError = true
                                        }

                                        if (self.isApp) {
                                            const params = {
                                                state: selState,
                                                product: oProduct.label.id,
                                                quantity: self.num_copies,
                                                data: JSON.stringify(self.oParams),
                                                platform: 1,
                                                template: format.id,
                                                label: format.label_id,
                                                printData: self.printData,
                                                status: 2
                                            }

                                            self.$store.dispatch('label/addPrintLabelLogAndroid', params).then((response) => {
                                                self.labelQueue()
                                            })
                                        }

                                        self.$snackbar.error({ closeable: true, message: msg })
                                    }

                                    // self.initStatusPrinter(false)
                                    self.activePrint = false
                                    self.printQueue.shift()
                                    if (self.printQueue.length > 0) {
                                        self.printPopup()
                                    } else {
                                        // self.labelSelected = false
                                    }
                                })
                        } else {
                            self.doPrintQueue()
                        }
                    }
                }
            } else {
                self.activePrint = false
                // console.log('WITHOUT PRINTER')
                // TODO - Create global function to check logged-in user permissions
                var emp_id = self.$store.getters['loginUser/getLocalEmployee']
                var emp = self.$store.getters['employee/getEmployee'](emp_id)
                if (self.getRoleLoggedUser && self.getRoleLoggedUser.level > 1) {
                    self.$popup.confirm({
                        message: self.$t('label.printer.alert_empty'),
                        title: self.$t('popup.confirm.title'),
                        textSave: self.$t('popup.confirm.text_save'),
                        textCancel: self.$t('label.printer.alert_later'),
                        callSave: function () {
                            self.$router.push('/config/printer')
                        }
                    })
                } else {
                    self.$popup.alert({
                        message: self.$t('label.printer.config_user')
                    })
                }
            }
            // REFRESH QUEUE LABEL PRINT
            // this.labelQueue()
        },

        printNextState(oProcess, oProduct, label) {
            var state = false
            var self = this

            for (var i in oProcess) {
                var step = oProcess[i]

                if (step.keyname == 'state') {
                    // console.log(label)
                    // console.log(step)
                    state = label.states[self.next_state]
                    // next_date = moment().add(state.time, 'minutes').format('YYYY-MM-DD HH:mm');

                    oProduct.selectedState.id = state.id
                    oProduct.selectedState.name = state.name

                    step.selected = state.id
                    step.result = state.name
                    step.tags[step.selected].next = false

                    // } else if (step.type == 'date') {
                    //   var aux_date = moment(
                    //     self.oParams['date'],
                    //     'DD/MM/YYYY HH:mm'
                    //   );
                    //   (self.oParams['print_date'] = aux_date.format(
                    //     'DD/MM/YYYY HH:mm'
                    //   )),
                    //     (self.oParams['print_date_day'] = aux_date.format(
                    //       'DD/MM/YYYY'
                    //     )),
                    //     (self.oParams['print_date_time'] = aux_date.format(
                    //       'HH:mm'
                    //     )),
                    //     (step.result = self.oParams[step.keyname + '_day'])
                }
            }

            log('Next state to send')
            log(oProcess)
            log(oProduct)
            log(self.oParams)

            // console.log('/ PRINT AGAIN ////////////////////////////////////////////////////////////////////////////')
            self.print(oProcess, oProduct, self.oParams, self.oParams['date'])
        },

        printNextAmasada(oProcess, oProduct, label) {
            log('Next AMASADA to send')
            log(JSON.parse(JSON.stringify(this.oParams)))
            this.print(oProcess, oProduct, this.oParams, this.oParams['print_date'])
        },

        doPrintQueue() {
            log('doPrintQueue')

            const self = this
            const isPrinting = self.$store.getters['label/getIsPrinting']

            log('isPrinting', isPrinting)

            if (!isPrinting) {
                self.$store
                    .dispatch('label/resetQueue')
                    .then(() => {
                        self.$store
                            .dispatch('label/getFilteredLogsAndroid', {
                                status: [0, 2]
                            })
                            .then((queue) => {
                                log('queue', JSON.parse(JSON.stringify(queue)))

                                if (Object.keys(queue).length > 0) {
                                    log('printQueueLabels')
                                    self.$store
                                        .dispatch('printer/printQueueLabels', queue)
                                        .then(() => {
                                            log('FINISH printQueueLabels...')
                                            let numberOfCopies = 0

                                            for (let index in queue) {
                                                numberOfCopies += parseInt(queue[index].quantity)
                                            }

                                            let msg = i18n.tc('config.printer.success_queue_print', numberOfCopies)
                                            msg = msg.replace('[num_copies]', numberOfCopies)
                                            log(msg)

                                            self.$snackbar.progress({
                                                closeable: true,
                                                message: msg
                                            })

                                            self.doPrintQueue()
                                        })
                                        .catch((error) => {
                                            logError('error print queue label', error)
                                            const appVersion = process.env.VUE_APP_VERSION
                                            self.$store.dispatch('label/addPrintError', {
                                                error: self.printerError,
                                                platform: 1,
                                                version: appVersion
                                            })
                                        })
                                } else {
                                    log('NO MORE LABELS TO PRINT')
                                    self.labelQueue()
                                }
                            })
                            .catch((error) => {
                                logError('error get filtered logs android', error)
                            })
                    })
                    .catch((error) => {
                        logError('error reset queue', error)
                    })
            }
        },

        getFirstCollection() {
            let collections = Object.keys(this.$store.getters['label/getCollections'])
            if (this.sSelectedCollection == -1 && typeof collections[0] !== 'undefined') {
                this.sSelectedCollection = collections[0]
            }
        },

        checkShowPrintButton() {
            // check if is not print page
            if (this.aStatesToPrint.length == 0) {
                let collections = this.$store.getters['label/getCollections']
                let collection = collections[this.sSelectedCollection]

                // loop for each reference
                for (let key in collection.references) {
                    let reference = collection.references[key]
                    // loop for each state
                    for (let key2 in reference.states) {
                        let state = reference.states[key2]

                        // check if state selected is undefined or state is selected, show button
                        // by default, state is selected and doesn't have selected attribute
                        if (typeof state.selected === 'undefined' || state.selected) {
                            // return this.bCollectionPrintButton = true;
                            this.$bar.addAction('print', {
                                callback: () => {
                                    this.printStates()
                                },
                                opened: true,
                                label: i18n.t('label.predefined_print')
                            })
                            return true
                        }
                    }
                }
            }

            this.$bar.reset()
            this.$bar.hide()
            return false
        },

        printStates() {
            let states = []
            let collections = this.$store.getters['label/getCollections']
            let collection = collections[this.sSelectedCollection]

            // loop for each reference
            for (let key in collection.references) {
                let reference = collection.references[key]

                // loop for each state
                for (let key2 in reference.states) {
                    // get state
                    let state = reference.states[key2]

                    // set only this state in label
                    let oState = {}
                    oState[state.id] = reference.states[key2]

                    // check if state selected is undefined or state is selected, add to array
                    // by default, state is selected and doesn't have selected attribute
                    if (typeof state.selected === 'undefined' || state.selected) {
                        states.push({
                            id: reference.id,
                            stateID: key2,
                            collectionID: this.sSelectedCollection,
                            referenceID: reference.id,
                            name: reference.name,
                            state: state.name,
                            image: reference.image,
                            quantity: state.quantity,
                            category_id: reference.category_id,
                            states: oState,
                            note: reference.note
                        })
                    }
                }
            }

            this.labelSelected = true

            this.$bar.reset()
            this.$bar.hide()
            this.aStatesToPrint = states
        },

        // labelImage(label) {
        //     return fnCheckConnection() ? label.image : false
        // },

        calcStates() {
            if (['landscape', 'desktop'].includes(this.$mq)) {
                if (document.getElementById('states')) {
                    this.statesHeight = document.getElementById('states').offsetHeight
                } else this.statesHeight = 60

                if (this.$refs.message) {
                    this.messageHeight = this.$refs.message.clientHeight
                }
                if (!this.numberOfStatesToShow) {
                    if (this.$refs) {
                        var options = this.$refs.state_options ? this.$refs.state_options.clientWidth : 0
                        var all = this.$refs.state_all ? this.$refs.state_all.clientWidth : 0
                        var more = this.$refs.state_more ? this.$refs.state_more.clientWidth : 0

                        var available = options - all - more - 30 // 30px of margin
                        var counter = 0

                        if (this.$refs.states) {
                            while (this.$refs.states.length > counter && this.$refs.states[counter].clientWidth > 0 && available > this.$refs.states[counter].clientWidth) {
                                available = available - this.$refs.states[counter].clientWidth
                                counter++
                            }
                        }

                        this.numberOfStatesToShow = counter
                    }
                }
            }
        },

        sortByName(oObject) {
            return Object.values(oObject).sort(function (oObjectA, oObjectB) {
                return oObjectA.name > oObjectB.name ? 1 : -1
            })
        },
        sortByPriority(oObject) {
            return Object.values(oObject).sort(function (oObjectA, oObjectB) {
                return parseInt(oObjectA.priority) > parseInt(oObjectB.priority) ? 1 : -1
            })
        },
        cleanSearch() {
            this.searchInput = ''
            this.debounceSearchInput = ''
        },
        closePrintErrorQueue() {
            this.$router.push({ name: 'Label' })
            this.queueSelected = false
            this.labelQueue()
        },
        calcGrid() {
            // Update number of columns
            var width = window.innerWidth
            var max_items = 5
            for (var w in this.breakpoints) {
                if (width < w) {
                    max_items = this.breakpoints[w]
                    break
                }
            }
            this.num_columns = max_items
        },
        calcResize() {
            if (!this.queueSelected && !this.labelSelected) {
                console.warn('calcResize')
                clearTimeout(this.timer)
                var self = this
                this.timer = setTimeout(function () {
                    log('calcResize')
                    self.calcGrid()
                    if (['landscape', 'desktop'].includes(self.$mq)) {
                        self.calcStates()
                    }
                }, 300)
            }
        },
        formatTimeToMax(timeToFormat) {
            // console.log(timeToFormat)
            if (typeof timeToFormat === 'undefined') return { result: null, format: 'minute' }
            var year = 365 * 24 * 60
            var month = 30 * 60 * 24
            var day = 60 * 24
            var hours = 60
            var result = false
            var format = false

            // if (timeToFormat % year == 0) {
            //     result = Math.floor(timeToFormat / year)
            //     format = 'year'
            // } else if (timeToFormat < year && timeToFormat % month == 0) {
            //     result = Math.floor(timeToFormat / month)
            //     format = 'month'
            // } else if (timeToFormat > year) {
            //     // Se calculan los años enteros y se obtienen los meses restantes, si los meses son enteros, los devuelve
            //     var years = Math.floor(timeToFormat / year)
            //     var months = timeToFormat - years * year
            //     if (months % month == 0) {
            //         result = Math.floor(months / month) + years * 12
            //         format = 'month'
            //     }
            // } else if (timeToFormat % day == 0) {
            //     result = Math.floor(timeToFormat / day)
            //     format = 'day'
            // } else if (timeToFormat % hours == 0) {
            //     result = Math.floor(timeToFormat / hours)
            //     format = 'hour'
            // } else {
            //     result = timeToFormat
            //     format = 'minute'
            // }
            result = timeToFormat
            format = 'minute'
            return { result: result, format: format }
        }
    },
    created() {
        // ORIENTATION LOCK IF SCREEN WIDTH IS LESS THAN 550 *viewport
        if ((window.screen.width < 550 || window.screen.height < 550) && this.isApp) {
            screen.orientation.lock('portrait')
        }

        this.labelQueue()
        this.can_validateprinter = true
        this.load()
        window.addEventListener('resize', this.calcResize)
    },
    destroyed() {
        if (document.getElementById('label_container')) document.getElementById('label_container').removeEventListener('scroll', this.getScroll)
        this.$popup.close()
        window.removeEventListener('resize', this.calcResize)

        // ORIENTATION UNLOCK
        screen.orientation.unlock()
    },
    mounted() {
        this.checkAccess()
        if (['landscape', 'desktop'].includes(this.$mq)) {
            if (document.getElementById('label_container')) {
                document.getElementById('label_container').addEventListener('scroll', this.getScroll)
            }
            this.calcResize()
        }

        this.$root.$on('label', (label) => {
            // your code goes here
            this.clickLabel(label)
        })
    },
    updated: function () {
        this.calcResize()
    },
    beforeRouteLeave(to, from, next) {
        // TODO hide tooltips
        this.$popup.close()
        this.$snackbar.close()
        this.can_validateprinter = false
        // clearInterval(this.iIntervalStatusPrinter)
        next()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.label {
    $fast-bar-width: 35px;
    $size-bolita: rem(18px);
    $icon-size: rem(60px);

    .content.right {
        position: relative;
        padding-bottom: 0 !important;
        overflow-y: hidden !important;

        .empty-page {
            min-height: 70vh;
            display: grid;
            place-items: center;
        }

        .states {
            margin-top: rem(15px);
            padding-left: rem(6px);
            margin-bottom: rem(5px);
            width: 100%;
            position: static;
            top: 0;
            background-color: $default-bg-color;

            &.alert-active {
                top: 70px;
            }

            .states-title {
                @include font-size(sm);
                font-family: $conden;
                width: 100%;
                text-align: left;
                display: inline-block;
                padding-left: 6px;
                color: $neutro-s70;
            }

            .state-options {
                display: inline-table;
                // width: calc(100% - 100px);
                width: calc(100%);
                height: auto;
                overflow: all;

                .state {
                    @include interaction();
                    display: inline-block;
                    background-color: #fff;
                    padding: 7px 15px;
                    border-radius: 3px;
                    width: max-content;
                    margin: 5px;
                    height: fit-content;
                    font-family: $text;
                    text-transform: uppercase !important;
                    white-space: nowrap;

                    @include bgHover(#fff);

                    &.selected {
                        background-color: $main;
                        color: #fff;

                        @include bgHover($main);
                    }

                    &.hide {
                        display: none;
                    }

                    &.display {
                        @include background($image: img('down_arrow_neutro_s70.svg'), $size: 18px, $position: left 5px center);
                        background-color: darken($default-bg-color, 5%);
                        color: $neutro-s70;
                        text-transform: unset !important;
                        padding-left: 24px;

                        @include bgHover(darken($default-bg-color, 5%));

                        &.less {
                            @include background($image: img('up_arrow_neutro_s70.svg'), $size: 18px, $position: left 5px center);
                        }
                    }

                    &.transparent {
                        background-image: none;
                        background-color: transparent;
                        color: transparent;
                        content: '';
                    }
                }
            }
        }

        .search-container {
            position: relative;
            padding-right: 11px;
            padding-left: 11px;
            margin: 0;

            .search {
                @include display-flex();
                @include align-items(center);
                @include background($image: img('search_neutro.svg'), $size: 18px, $position: left 12px center);
                @include border-radius(4px);
                @include font-size(ml);
                height: 50px;
                width: 100%;
                background-color: #fff;
                padding: 0 70px 0 40px;
                border-bottom: none;
                font-family: $text;
                cursor: text;
            }

            .close {
                @include border-radius(4px);
                @include background($image: img('close_dark.svg'), $size: 12px, $position: center center);
                width: 33px;
                height: 33px;
                position: absolute;
                top: 10px;
                right: 40px;
                background-color: $neutro-t50;
                cursor: pointer;
            }
        }

        .container {
            @include display-flex();
            @include flex-direction(row);
            @include flex-wrap(wrap);
            @include align-content(flex-start);
            @include align-items();
            max-width: unset;
            width: calc(100%);
            padding: 6px;
            margin: 0;
            float: left;
            overflow: auto;
            height: calc(100% - 60px);
            // margin-top: 60px;
            position: static;
            bottom: 0;

            .virtual-list {
                height: 360px;
                overflow-y: auto;
                width: 100%;
            }

            .list-keep {
                width: 100%;
                height: 100%;
                border-radius: 3px;
                overflow-y: auto;
                & > div {
                    display: flex;
                    flex-wrap: wrap;
                }

                .list-item-keep {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 5px;
                    // width: 100%;
                    // display: flex;
                    // flex-wrap: wrap;
                }
            }

            &.alert-active {
                // margin-top: 130px;
            }

            .item {
                @include interaction();
                @include align-items();
                @include border-radius(3px);
                line-height: 1;
                background-color: #fff;
                text-align: center;
                width: 18% !important;
                margin: 6px 1%;
                /*min-width: 160px;*/
                height: rem(150px);
                padding: 12px;
                position: relative;

                @include bgHover(#fff);

                // @media (max-width: 1200px) {
                //     width: 23% !important;
                // }

                @media (max-width: 1100px) {
                    width: 31% !important;
                }

                @media (max-width: 800px) {
                    width: 48% !important;
                }

                .color {
                    @include border-radius($size-bolita);
                    width: $size-bolita;
                    height: $size-bolita;
                    position: absolute;
                    top: 10px;
                    left: 10px;
                }

                .image {
                    display: block;
                    margin: 10px auto;
                    text-align: center;
                    height: $icon-size;
                    // width: $icon-size;

                    img {
                        // height: $icon-size;
                        // width: $icon-size;
                        height: 100%;
                        // margin: 10px auto;
                        object-fit: contain;
                    }
                }

                .imageapp {
                    @include background($image: img('offline/no_icon_light.svg'));
                    display: block;
                    margin: 10px auto;
                    height: $icon-size;
                    width: $icon-size;

                    &.appempty {
                        @include background($image: img('offline/no_icon_light.svg') !important);
                    }
                }

                .text {
                    @include display-flex();
                    @include align-items();
                    @include justify-content();
                    width: 100%;
                    height: 50px;
                    text-align: center;
                    margin-top: 5px;
                    font-family: $text-bold;
                }

                &.selected {
                    background-color: $accent-t50;
                }

                &.highlight {
                    transition: 0.1s;
                    transform: translate(0, -5px);
                    @include box-shadow($h: 0px, $v: 10px, $b: 10px, $s: -3px, $c: rgba(100, 100, 100, 0.5));
                }

                /*&:active {
          animation-name: itemHold;
          animation-duration: 125ms;
          animation-delay: 175ms;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }*/

                &.no-photo {
                    height: rem(80px);

                    .image {
                        display: none;
                    }

                    .color {
                        top: 30px;
                        margin: 0 10px;
                        left: 0;
                    }

                    .text {
                        @include justify-content(left);
                        width: calc(100% - #{$size-bolita});
                        text-align: left;
                        margin-left: $size-bolita + rem(10px);
                        overflow: hidden;
                        margin-top: 0;
                        height: 100%;
                    }
                }
            }
            @include keyframes(itemHold) {
                100% {
                    background-color: $accent-t50;
                }
            }

            .empty {
                @include background($image: img('empty4.svg'), $size: 70px, $position: top center);
                margin-top: calc(50% - #{$menu-top-height} - 70px);
                padding-top: 80px;
                width: 100%;
            }
        }

        .fast-bar {
            @include display-flex();
            @include flex-direction(row);
            @include flex-wrap(wrap);
            @include align-content(flex-start);
            @include align-items();
            width: $fast-bar-width;
            height: calc(100% - 60px);
            float: right;
            transition: 0.1s;
            position: absolute;
            right: -0px;
            z-index: 50;
            //@include background($color: $default-content-color);
            overflow: hidden;
            bottom: 0;

            &.hide {
                width: 0px;
                transition: 0.5s;
            }

            &:hover {
                width: $fast-bar-width !important;
            }

            .btn {
                @include background($color: transparent);
                font-size: rem(16px);
                color: $main-text;
                line-height: 0;
                font-family: $text-bold;
                display: block;
                padding-top: 5px;
                padding-bottom: 5px;
                width: $fast-bar-width;
                height: calc(100% / 25);

                &.empty {
                    color: #bbb;
                    cursor: default;
                }

                &.active {
                    background-color: $main;
                    color: #fff;
                }

                &.highlight {
                }
            }
        }

        .fast-bar + .container {
            /*width: calc(100% - #{$fast-bar-width});*/
            width: 100%;
        }

        &.right {
            width: calc(100% - 200px) !important;

            .container {
                .item {
                    width: 31%;
                    margin: 6px 1%;
                }
            }

            &.full {
                width: 100% !important;
            }
        }
    }

    .tabulation {
        height: 50px;
        width: 200px !important;
        padding-top: 10px;
        display: flex;

        .category,
        .collection {
            @include interaction();
            @include border-radius(6px);
            flex-grow: 1;

            @include bgHover($default-bg-color);

            &.selected {
                flex-grow: 10;
                background-color: #fff;

                .text {
                    height: 40px;
                    width: 40px;
                    float: left;
                    display: initial;
                    @include font-size(m);
                    font-family: $text-bold;
                    color: $main;
                    display: flex;
                    align-items: center;
                }

                @include bgHover(#fff);
            }
            .icon {
                height: 40px;
                width: 40px;
                float: left;
            }
            .text {
                display: none;
            }
        }

        .category {
            .icon {
                @include background($image: img('label_main.svg'), $size: 20px, $position: center center);
            }
        }

        .collection {
            .icon {
                @include background($image: img('collection_main.svg'), $size: 20px, $position: center center);
            }
        }
    }

    .column.categories {
        @include box-shadow($h: 0px, $v: 0px, $b: 6px, $s: 0px, $c: rgba($neutro-s90, 0.25));
        width: 200px !important;
        height: 100% !important;
        border-radius: 5px;

        &.column-withtab {
            height: calc(100% - 50px) !important;
        }

        .tab {
            @include font-size(sm);
            color: #888;
            font-family: $text;
            text-align: center;
            margin: 0 auto 0 auto;
            padding: 12px 6px;
            width: 50%;
            height: 40px;
            display: inline-block;

            &.selected {
                color: $main-text;
                border-bottom: 2px solid $main-light;
            }
        }

        .item {
            @include interaction();
            @include display-flex();
            @include align-items();
            @include justify-content(space-between);
            @include font-size(m);
            line-height: 1;
            color: #555;
            min-height: rem(60px);
            padding: 4px 12px;
            margin: 4px;
            border-radius: 5px;
            // background-color: #fff;

            .color {
                width: $size-bolita;
                height: $size-bolita;
                border-radius: 100%;
            }

            .text,
            .count {
                display: inline-block;
                font: inherit;
                @include font-size(m);
                font-family: $text;
                line-height: inherit;
                white-space: break-spaces;
                word-break: break-word;
            }

            .text {
                width: calc(100% - #{$size-bolita + rem(10px)});
            }
            .count {
                @include background($color: $main-t80);
                @include border-radius(20px);
                @include font-size(s);
                font-family: $text;
                line-height: 20px;
                min-width: 20px;
                width: auto;
                height: 20px;
                text-align: center;
                padding: 0px 4px;
            }

            @include bgHover($inactive-t90);

            &.selected {
                background-color: $main;

                .text {
                    color: white;
                    font-family: $text-bold;
                }

                .color {
                    filter: brightness(75%);
                }

                @include bgHover($main);
            }

            &.all {
            }

            &:active {
                animation-name: itemHoldCat;
                animation-duration: 125ms;
                animation-delay: 175ms;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
            @include keyframes(itemHoldCat) {
                100% {
                    background-color: #f9f9f9;
                    border-left-color: $accent-dark;
                }
            }
        }

        .collection {
            @include interaction();
            @include display-flex();
            @include align-items();
            @include justify-content(space-between);
            @include font-size(m);
            line-height: 1;
            color: #555;
            min-height: rem(60px);
            padding: 4px 12px;
            margin: 4px;
            border-radius: 5px;

            @include bgHover($inactive-t90);

            &.selected {
                font-family: $text-bold;
                background-color: $main;
                color: #fff;

                @include bgHover($main);
            }
        }
    }

    #collections {
        padding: 10px 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-content: start;
        column-gap: 15px;
        row-gap: 15px;
        align-items: start;
        padding-bottom: 70px;
    }

    @media (max-width: 1300px) {
        #collections {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    @media (max-width: 1100px) {
        #collections {
            grid-template-columns: 1fr 1fr;
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.portrait #content.label,
.landscape #content.label {
    #message {
        padding: 0 10px 0 15px;
    }
}
.portrait #content.label {
    $size-bolita: rem(18px);

    .portrait-label-container {
        @include display-flex();
        @include flex-direction(column);
        height: 100%;
        padding: 12px;

        .empty-page {
            // min-height: 70vh;
            height: 100%;
            display: grid;
            place-items: center;
        }

        .portrait-menu {
            width: 100%;
            height: auto;
            // padding-top: 5px;
        }
        .content {
            padding: 0px !important;
            width: 100% !important;
            overflow-y: unset !important;

            #message {
                padding: 0;
                padding-left: 10px;
            }
        }
        .categories-container {
            height: calc(100%);
            width: 100%;
            overflow-y: scroll;

            .portrait-categories {
                width: 100%;
                height: 100%;

                #collections.right {
                    float: none !important;

                    @media screen and (max-width: 500px) {
                        grid-template-columns: 1fr;
                    }
                    padding-bottom: 80px !important;
                }

                .item {
                    @include interaction();
                    @include display-flex();
                    @include align-items();
                    @include justify-content(space-between);
                    @include font-size(m);
                    line-height: 1;
                    color: #555;
                    min-height: rem(60px);
                    padding: 4px 12px;
                    margin: 0px 0px 4px 0px;
                    border-radius: 5px;
                    background-color: #fff;

                    .color {
                        width: $size-bolita;
                        height: $size-bolita;
                        border-radius: 100%;
                    }
                    .text {
                        font-family: $text-bold;
                    }
                }

                .text,
                .count {
                    display: inline-block;
                    font: inherit;
                    @include font-size(m);
                    font-family: $text;
                    line-height: inherit;
                    white-space: break-spaces;
                    word-break: break-word;
                }

                .text {
                    width: calc(100% - #{$size-bolita + rem(10px)});
                }
                .count {
                    @include background($color: $main-t80);
                    @include border-radius(20px);
                    @include font-size(s);
                    font-family: $text;
                    line-height: 20px;
                    min-width: 20px;
                    width: auto;
                    height: 20px;
                    text-align: center;
                    padding: 0px 4px;
                }

                @include bgHover($inactive-t90);

                &.selected {
                    background-color: $main;

                    .text {
                        color: white;
                        font-family: $text-bold;
                    }

                    .color {
                        filter: brightness(75%);
                    }

                    @include bgHover($main);
                }

                &.all {
                }

                &:active {
                    animation-name: itemHoldCat;
                    animation-duration: 125ms;
                    animation-delay: 175ms;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
                @include keyframes(itemHoldCat) {
                    100% {
                        background-color: #f9f9f9;
                        border-left-color: $accent-dark;
                    }
                }
                .label-content {
                    display: block;
                    width: 100%;
                    height: 100%;
                    align-content: flex-start;
                }
                &:hover {
                    background-color: #f9f3f2;
                }
            }
        }

        #label_container {
            height: 100%;
            padding: 0;

            .virtual-list {
                height: 360px;
                overflow-y: auto;
                width: 100%;
            }

            .list-keep {
                width: 100%;
                height: 100%;
                border-radius: 3px;
                overflow-y: auto;
                & > div {
                    display: flex;
                    flex-wrap: wrap;
                }

                .list-item-keep {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 5px 0px;
                    // width: 100%;
                    // display: flex;
                    // flex-wrap: wrap;
                }
            }
        }
    }
}
</style>
